'use strict'


// import { DeviceMsgsModule }    from '../../messages/index.mjs';

import {
    DeviceCommon,
    DeviceHelper
}                              from '../Devices/index.mjs';
import { ElementCommon }       from '../Element.class.mjs';
import { ElementHelperCommon } from '../Element-helper.class.mjs';
import { ElementMainCommon }   from '../ElementMain.class.mjs';
import { ElementState }        from '../ElementState.class.mjs';
import { ElementType }         from '../ElementType.class.mjs';


const _attrs = {
    _address:         '_address',
    _city:            '_city',
    _country:         '_country', 
    _device:          '_device',
    _deviceQuantity:  '_deviceQuantity',
    _description:     ElementCommon.attrs._description,
    _iconPath:        '_iconPath',
    _id:              ElementCommon.attrs._id,
    _name:            ElementCommon.attrs._name,
    _postCode:        '_postCode',
    _telephoneNumber: '_telephoneNumber'
}; // _attrs


// const _da    = DeviceMsgsModule.attributes;
// const _field = _da.site;
const _field = ElementType.Site;//_da.site;


export class Site extends ElementMainCommon
{
    static get(d, attrs)
    {
        return d
            ? (d instanceof Site
                ? d
                : new Site(d, attrs)
            )
            : undefined;
    }


    // Getters
    static get field()
    {
        return _field;
    }


    static get attrs()
    {
        return _attrs;
    }


    get address()
    {
        return this[Site.attrs._address];
    }

    get addressStr()
    {
        // Parentheses essential here
        return (
              (this.address  ?        this.address  : "")
            + (this.city     ? ", " + this.city     : "")
            + (this.postCode ? ", " + this.postCode : "")
            + (this.country  ? ", " + this.country  : "")
        );
    }


    get city()
    {
        return this[Site.attrs._city];
    }


    get country()
    {
        return this[Site.attrs._country];
    }


    get deviceList()
    {
        return this[Site.attrs._device];
    }
    

    get devices()
    {
        return Number.isInteger(this[Site.attrs._deviceQuantity])
            ? this[Site.attrs._deviceQuantity]
            : 0;
    }


    // [TBD - needed for Web to build is using OrganisationCommon directly]
    get id()
    {
        return this._id;
    }


    // [TBD - needed for Web to build is using OrganisationCommon directly]
    get name()
    {
        return this._name;
    }

    
    get postCode()
    {
        return this[Site.attrs._postCode];
    }


    get telephoneNumber()
    {
        return this[Site.attrs._telephoneNumber];
    }


    //
    // Public methods
    //

    // Override
    setState()
    {
        // Update state icon based on lowest of constituent devices

        let e = ElementState.None; // Only keep if no devices
        if (Array.isArray(this.deviceList)) {
            e = ElementState.Unknown;
            this.deviceList.forEach((d) => {
                if (d instanceof DeviceCommon) switch (d.state) {
                    case ElementState.Good:
                        if (ElementState.Average !== e && ElementState.Poor !== e) e = d.state;
                    break;

                    case ElementState.Average:
                        if (ElementState.Poor !== e) e = d.state;
                    break;

                    case ElementState.Poor:
                        e = d.state;
                    break;

                    case ElementState.None:
                        // Fall through
                    case ElementState.Unknown:
                        return d.state;
                } // switch
            }); // forEach
        }

        return (this.state = e);
    }


    //
    // Protected methods
    //

    // Override
    _merge(d, attrsE, attrsI)
    {
        super._merge(d, attrsE, {...Site.attrs, ...attrsI});

        this[Site.attrs._device] = ElementHelperCommon.convertArray(this, Site.attrs._device, DeviceHelper.field);
        if ( this[ElementMainCommon.attrs._state]) this.setState(); // need to re-calc after updating devices
        
        return this;
    }
}