'use strict'


// import { DeviceMsgsModule } from '../../messages/index.mjs';

import { ElementCommon } from '../Element.class.mjs';
import { ElementType }   from '../ElementType.class.mjs';


export const UserRoles = {
    administrator:    'administrator',
    orgAdministrator: 'orgadministrator',
    readonly:         'readonly',
    user:             'user'
};
export const _userRoles = UserRoles;


const _attrs = {
    _comment:       '_comment',
    _createddate:   '_createddate',
    _email:         '_email',
    _expiresin:     '_expiresin',
    _externalcheck: '_externalcheck',
    _extraurl:      '_extraurl',
    _landingpage:   '_landingpage',
    _name:          '_name',
    _options:       '_options',
    _organisation:  '_organisation',
    _token:         '_token',
    _username:      '_username',
    _userrole:      '_userrole'
};


// const _da    = DeviceMsgsModule.attributes;
// const _dv    = DeviceMsgsModule.values;
const _field = ElementType.User;//_da.site; // [TBD]


export class User extends ElementCommon
{
    static get(d, attrs)
    {
        return d
            ? (d instanceof User
                ? d
                : new User(d, attrs)
            )
            : undefined;
    }


    static get field()
    {
        return _field;
    }


    static get attrs()
    {
        return _attrs;
    }


    static get userRoles()
    {
        return _userRoles;
    }


    //
    // Getters
    //
    get createdDate()
    {
        return this[User.attrs._createddate];
    }


    get email()
    {
        return this[User.attrs._email];
    }


    get expiresIn()
    {
        return this[User.attrs._expiresin];
    }

    set expiresIn(d)
    {
        return (this[User.attrs._expiresin] = d);
    }


    get externalCheck()
    {
        return this[User.attrs._externalcheck];
    }


    get extraUrl()
    {
        return this[User.attrs._extraurl];
    }

    
    // Override
    get isValid()
    {
        return !!(this.username && this.token);
        // return this.username && this.token ? true: false;
    }


    get landingPage()
    {
        return this[User.attrs._landingpage];
    }

    set landingPage(d)
    {
        return (this[User.attrs._landingpage] = d);
    }


    get name()
    {
        return this[User.attrs._name];
    }


    get options()
    {
        return this[User.attrs._options];
    }


    get organisation()
    {
        return this[User.attrs._organisation];
    }


    get token()
    {
        return this[User.attrs._token];
    }

    set token(d)
    {
        return (this[User.attrs._token] = d);
    }


    get username()
    {
        return this[User.attrs._username];
    }


    get userrole()
    {
        return this[User.attrs._userrole];
    }


    //
    // Private methods
    //

    // Override
    _merge(d, attrsE, attrsI)
    {
        return super._merge(d, attrsE, {...User.attrs, ...attrsI});
    }
}