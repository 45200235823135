'use strict'


// import { ElementCommon }     from  '../Element.class.mjs';

import { ElementType } from '../ElementType.class.mjs';
import { Test }        from './Test.class.mjs'; // has to be like this otherwise runtime error
import {
    // Test,
    TestDns,
    TestFtp,
    TestHttp,
    TestIcmp,
    TestUdp,
    TestVoice,
    TestWeb,
    TestYoutube,
    TestTypes
}               from './index.mjs';

// import { DeviceMsgsModule } from '../../messages/index.mjs';


const _field     = ElementType.Test;//DeviceMsgsModule.attributes.test;
const _typeField = Test.typeField;


export class TestHelper
{
    // Factory method
    static get(d, attrs)
    {
        if (d instanceof Test) return d;

        let fn = undefined;
        if (d) {
            switch (TestHelper._get(d, attrs ? attrs[_typeField] : undefined)) {
                case TestTypes.dns:
                    fn = TestDns.get;
                    break;

                case TestTypes.ftp:
                case TestTypes.sftp:
                    fn = TestFtp.get;
                    break;

                case TestTypes.http:
                    fn = TestHttp.get;
                    break;

                case TestTypes.icmp:
                    fn = TestIcmp.get;
                    break;

                case TestTypes.udp:
                    fn = TestUdp.get;
                    break;

                case TestTypes.voice:
                    fn = TestVoice.get;
                    break;

                case TestTypes.web:
                    fn = TestWeb.get;
                    break;

                case TestTypes.youtube:
                    fn = TestYoutube.get;
                    break;
            } // switch
        }

        return fn instanceof Function
            ? fn(d, attrs)
            : undefined;
    }


    static get field()
    {
        return _field;
    }

    
    //
    // Private methods
    //
    static _get(d, t)
    {
        if (! t) t = _typeField;
        return d && t
            ? (d[t] != null
                 ? d[t]
                 : undefined)
            : undefined;
    }
}