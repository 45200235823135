'use strict';


// 2020-05-29
// Can't be static class objects in ES2016, not supported until ES?, need to wait for browser support


const _gpsDistanceAccuracy      = 25;                  // metres
const _maxId                    = 2147483647;          // used as error ID from end devices
const _msPerDay                 = 1000 * 60 * 60 * 24; // ms
const _testResultsDecimalPlaces = 2;
const _testResultsInvalid       = "NA";


// const _mobileTechnology = {
//     'cdma':    'cdma',
//     'gsm':     'gsm',
//     'lte':     'lte',
//     'nr':      'nr',  // [TBD] 5g
//     'tdscdma': 'tdscdma',
//     'umts':    'umts'
// }; // _mobileTechnology

const _testResultsCategories = {
    1: 'good',
    2: 'average',
    3: 'poor'
}; //_testResultsCategories


export class GlobalsCommonModule
{
    // Comment out so can only use class as static
    // constructor()
    // {

    // }

    static get gpsDistanceAccuracy()
    {
        return _gpsDistanceAccuracy;
    }


    static get maxId()
    {
        return _maxId;
    }

    
    // static get mobileTechnology()
    // {
    //     return _mobileTechnology;
    // }


    static get msPerDay()
    {
        return _msPerDay;
    }


    static get testResultsDecimalPlaces()
    {
        return _testResultsDecimalPlaces;
    }


    static get testResultsInvalid()
    {
        return _testResultsInvalid;
    }


    static get testResultsCategories()
    {
        return _testResultsCategories;
    }
}