'use strict'


// import { DeviceMsgsModule }       from '../../messages/index.mjs';

import { AttributesHelperCommon } from '../Devices/Attributes/index.mjs';
import { ElementCommon }          from '../Element.class.mjs';
import { ElementMainCommon }      from '../ElementMain.class.mjs';
import { ElementState }           from '../ElementState.class.mjs';
import { ElementType }            from '../ElementType.class.mjs';

import { Site }                   from '../Site/Site.class.js';
// import { ElementHelperCommon }          from '../index.mjs';


const _attrs = {
    _description:   ElementCommon.attrs._description,
    _iconPath:      '_iconPath',
    _id:            ElementCommon.attrs._id,
    _name:          ElementCommon.attrs._name,
    _sitePrimary:   '_sitePrimary',
    _sites:         '_sites',
    _sitesQuantity: '_sitesQuantity'
}; // attrs


// const _da    = DeviceMsgsModule.attributes;
// const _field = _da.organisation;
const _field = ElementType.Organisation;//_da.organisation;


export class Organisation extends ElementMainCommon
{
    static get(d, attrs)
    {
        return d
            ? (d instanceof Organisation
                ? d
                : new Organisation(d, attrs)
            )
            : undefined;
    }


    // Getters
    static get attrs()
    {
        return _attrs;
    }


    static get field()
    {
        return _field;
    }


    // [TBD - needed for Web to build is using Organisation directly]
    get id()
    {
        return this._id;
    }


    // [TBD - needed for Web to build is using Organisation directly]
    get name()
    {
        return this._name;
    }
    
       
    get sitePrimary()
    {
        return this[Organisation.attrs._sitePrimary];
    }


    get sites()
    {
        return this[Organisation.attrs._sites];
    }


    get sitesQuantity()
    {
        return this[Organisation.attrs._sitesQuantity];
    }


    //
    // Public methods
    //

    // Override
    _setState()
    {
        // Called externally to trigger state re-calc
        // Children can override if needed
        super.setState();

        console.log("Stu org set state 2")
        this.state = ElementState.None;
    }


    //
    // Protected methods
    //

    // Override
    _merge(d, attrsE, attrsI)
    {
        super._merge(d, attrsE, {...Organisation.attrs, ...attrsI});

        const o = Organisation._processSites(d);
        this[Organisation.attrs._sites] = o ? o : [];

        return this;
    }


    //
    // Private methods
    //
    static _processSites(d)
    {
        return (d)
            ? AttributesHelperCommon.convertArray(d, Organisation.attrs._sites, Site.field)
            : undefined;
    }
}