'use strict';


import {
    DeviceCommon,
    DeviceTypes
}                              from './Device.class.mjs';
import {
    AttributesHelperCommon,
    SimCommon
}                              from './Attributes/index.mjs';
import { ElementHelperCommon } from '../Element-helper.class.mjs';
import {
    MobileCellCommon,
    MobileCellHelperCommon
}                              from '../MobileCell/index.mjs';
// import { NotificationCommon }  from '../Notification/index.mjs';


const _attrsPop = {
    _airplanemode:     '_airplanemode',
    _numsupportedsims: '_numsupportedsims'
}; // _attrsPop

const _attrs = {
    ..._attrsPop,
    _geolocation:      '_geolocation',
    _mobilecell:       '_mobilecell',  // used for single mobilecell update
    _mobilecells:      '_mobilecells', // used to store all
    _power:            '_power',
    _sims:             '_sims',

    _surveyrunning:    '_surveyrunning',
    _testrunning:      '_testrunnung'
}; // _attrs


const _DeviceType = DeviceTypes.mobilephone;


// Actual code shared between NodeJS and AngularJS
export class DeviceMobilePhoneCommon extends DeviceCommon
{
    constructor(d, attrs)
    {
        super(d, attrs);

        this._type = _DeviceType;
    }


    //
    // Static methods
    //
    
    // Factory method
    // Override
    static get(d, attrs)
    {
        return d
            ? (d instanceof DeviceMobilePhoneCommon
                ? d
                : new DeviceMobilePhoneCommon(d, attrs)
              )
            : undefined;
    }


    static get attrs()
    {
        return _attrs;
    }


    static get NotificationSeverity()
    {
        // return NotificationCommon.NotificationSeverity;
    }


    //
    // Getters and setters
    //
    get airplaneMode()
    {
        return this[DeviceMobilePhoneCommon.attrs._airplanemode];
    }


    set cells(d)
    {
        if (Array.isArray(d)) d.forEach(a => {
            this.processCell(d);
        }); // forEach
    }


    // get geolocation()
    // {
    //     return this[DeviceMobilePhoneCommon.attrs._geolocation];
    // }
    

    get mobileCells()
    {
        return this[DeviceMobilePhoneCommon.attrs._mobilecells];
    }

    set mobileCells(d)
    {
        if (Array.isArray(d)) this[DeviceMobilePhoneCommon.attrs._mobilecells] = d;
    }
    
    
    get numSupportedSims()
    {
        return this[DeviceMobilePhoneCommon.attrs._numsupportedsims];
    }
    

    get power()
    {
        return this[DeviceMobilePhoneCommon.attrs._power];
    }


    get sims()
    {
        return this[DeviceMobilePhoneCommon.attrs._sims];
    }

    set sims(d)
    {
        if (Array.isArray(d)) this[DeviceMobilePhoneCommon.attrs._sims] = d;
    }


    get surveyrunning()
    {
        return this[DeviceMobilePhoneCommon.attrs._surveyrunnng];
    }

    set surveyrunning(d)
    {
        this[DeviceMobilePhoneCommon.attrs._surveyrunnng] = d;
    }
        

    get testrunning()
    {
        return true;//this[DeviceMobilePhoneCommon.attrs._testrunnng];
    }

    set testrunning(d)
    {
        this[DeviceMobilePhoneCommon.attrs._testrunnng] = d;
    }
        

    //
    // Public methods
    //
    processMobileCell(d)
    {
        if (d = MobileCellHelperCommon.get(d)) {                        // handles if d already MobileCellCommon
            this[DeviceMobilePhoneCommon.attrs._mobilecells][d.id] = d; // will overwrite if ID is already present
        }

        return d;
    }


    // processPower(d)
    // {
    //     console.log("[TBD] Device Power process");
    // }


    //
    // Private methods
    //

    // Override
    _merge(d, attrs)
    {
        super._merge(d, attrs, attrs ? undefined : _attrsPop);

        if (d) {
            // Power
            let o = ElementHelperCommon.get(d, AttributesHelperCommon.fields.power, undefined, DeviceMobilePhoneCommon.attrs._power);
            // o = AttributesHelperCommon.get(d, DeviceMobilePhoneCommon.attrs._power);// AttributesHelperCommon.fields.power);
            if (o) this[DeviceMobilePhoneCommon.attrs._power] = o;

            // MobileCells
            o = DeviceMobilePhoneCommon._processMobileCells(d);
            // Now here, to merge the right cell, not blow away cell list
            if (o) {
                if (Array.isArray(o)) {
                    // Full mobile cell list, replace
                    this[DeviceMobilePhoneCommon.attrs._mobilecells] = o;
                }
                else {
                    if (o instanceof MobileCellCommon) {
                        // Individual mobile cell, update
                        if (Array.isArray(this[DeviceMobilePhoneCommon.attrs._mobilecells])) {
                            let found = false;
                            for (let i = 0; i < this[DeviceMobilePhoneCommon.attrs._mobilecells].length; i++) {
                                const mc = this[DeviceMobilePhoneCommon.attrs._mobilecells][i];
// console.debug("Stu mc searching: " + o.simslot + ", " + mc.simslot + ", " + (o.simslot === mc.simslot))
                                if (mc instanceof MobileCellCommon && o.simslot === mc.simslot) {
                                    this[DeviceMobilePhoneCommon.attrs._mobilecells][i] = o;
                                    found = true
                                }
                            } // for
                            
                            // Not found for update, might be new cell for a Sim, add
                            if (! found) this[DeviceMobilePhoneCommon.attrs._mobilecells].push(o);
                        }
                        else {
                            this[DeviceMobilePhoneCommon.attrs._mobilecells] = o;
                        }
                    }
                    else {
                        console.warn("Unknown object: " + JSON.stringify(o));
                    }
                }
            }
            else {
                this[DeviceMobilePhoneCommon.attrs._mobilecells] = [];
            }

            // Sims
            o = DeviceMobilePhoneCommon._processSims(d);
            if (o) this[DeviceMobilePhoneCommon.attrs._sims] = o;
        }

        return this;
    }


    static _processMobileCells(d)
    {
        return (d)
            ? AttributesHelperCommon.convertArray(d, DeviceMobilePhoneCommon.attrs._mobilecells, MobileCellHelperCommon.field)
            : undefined;
    }


    static _processSims(d)
    {
        const o = (d)
            ? AttributesHelperCommon.convertArray(d, DeviceMobilePhoneCommon.attrs._sims, AttributesHelperCommon.fields.sim)
            : undefined;

        // Convert to object
        const o2 = {};
        if (Array.isArray(o)) o.forEach((s) => {
            if (s && s[SimCommon.attrs._simslotnumber]) o2[s[SimCommon.attrs._simslotnumber]] = s;
        }); // forEach

        return o2;
    }
}