'use strict';


// Can't be static class objects in ES2016, not supported until ES?, need to wait for browser support

const _msgTypes = {
    // New
    get:                 'get',
    set:                 'set',        

    // Deprecated
    getdata:             'getdata',
    devicecmd:           'devicecmd'            
};


// New
const _msgTypesInfo = {
    device:              'device',
    survey:              'survey',

    clientconfig:        'clientconfig',
    connection:          'connection',
    devices:             'devices',
    devicegroups:        'devicegroups',
    devicelocations:     'devicelocations',
    devicenotifications: 'devicenotifications',
    deviceevents:        'deviceevents',
    disconnect:          'disconnect',
    disconnecting:       'disconnecting',
    disconnectwait:      'disconnectwait',
    error:               'error',
    geolocation:         'geolocation',
    getlogdebug:         'getlogdebug',
    groupinstances:      'groupinstances',
    insights:            'insights',
    heartbeat:           'heartbeat',
    heartbeatresp:       'heartbeatresp',
    init:                'init',
    kpis:                'kpis',
    kpisos:              'kpisoneshot',
    kpispd:              'kpisperiodic',
    login:               'login',
    logout:              'logout',
    // message:             'message',
    mobilecells:         'mobilecells',
    mobilecellsightings: 'mobilecellsightings',
    notifications:       'notifications',
    networkkpialarms:    'networkkpialarms',
    networklist:         'networklist',
    networkscores:       'networkscores',
    organisations:       'organisations',
    power:               'power',
    services:            'services',
    servicelist:         'servicelist',
    servicesos:          'servicesoneshot',
    servicespd:          'servicesperiodic',
    sites:               'sites',
    surveys:             'survey',
    surveypoint:         'surveypoint',
    testlist:            'testlist',
    testprofiles:        'testprofiles',
    testresults:         'testresults',
    wifiaps:             'wifiaps',
    wifiapsightings:     'wifiapsightings'
};


// New
const _msgTypesAction = {
    add:                 'add',
    clear:               'clear',
    config:              'config',
    delete:              'delete',
    disconnect:          'disconnect',
    find:                'find',
    logDebug:            'logDebug',
    send:                'send',
    start:               'start',
    stop:                'stop',
    update:              'update'
};


// New
const _msgTypesAttributes = {
    action:              'action',
    alarm:               'alarm',
    cause:               'cause',
    data:                'data',
    device:              'device',
    deviceId:            'deviceid',
    extra:               'extra',
    groupBy:             'groupby',
    info:                'info',
    id:                  'id',
    limitend:            'limitend',
    limitstart:          'limitstart',
    message:             'message',
    password:            'password',
    quantity:            'quantity',
    reconnect:           'reconnect',
    reinit:              'reinit',
    reqid:               'reqid',
    state:               'state',
    surveyId:            'surveyid',
    summary:             'summary',
    timer:               'timer',
    token:               'token',
    username:            'username'
};


// Deprecated
const _msgTypesSub = {
    configSend:          'configSend',
    devices:             'devices',
    devicegroups:        'devicegroups',
    devicelocations:     'devicelocations',
    deviceevents:        'deviceevents',
    disconnect:          'disconnect',
    find:                'find',
    geoLocation:         'geoLocation',
    getLogDebug:         'getLogDebug',
    groupinstances:      'groupinstances',
    insights:            'insights',
    kpis:                'kpis',
    kpisos:              'kpisoneshot',
    kpispd:              'kpisperiodic',
    login:               'login',
    mobilecells:         'mobilecells',
    mobilecellsightings: 'mobilecellsightings',
    notifications:       'notifications',
    networklist:         'networklist',
    organisations:       'organisations',
    services:            'services',
    servicelist:         'servicelist',
    servicesos:          'servicesoneshot',
    servicespd:          'servicesperiodic',
    sites:               'sites',
    testlist:            'testlist',
    testresults:         'testresults',
    wifiaps:             'wifiaps',
    wifiapsightings:     'wifiapsightings'
};


const _events = {
    alarm:               'alarm',
    battery:             'battery',
    device:              'device',
    devicegroup:         'devicegroup',
    geolocation:         'geolocation',
    notification:        'notification',
    survey:              'survey',
};


const _eventAttributes = {
    average:             'avg',
    datetime:            'datetime',
    devices:             'devices',
    groups:              'groups',
    id:                  'id',
    number:              'num',
    raw:                 'raw',
    value:               'val',
    devicecmd:           'devicecmd',

    batterycharging:     'batterycharging',
    batteryconnected:    'batteryconnected',
    batterylevel:        'batterylevel',

    serialnumber:        'serialnumber'
};



// Actual code shared between NodeJS and AngularJS
export class WebMsgsModule
{
    static get msgTypes()
    {
        return _msgTypes;
    }


    static get msgTypesInfo()
    {
        return _msgTypesInfo;
    }


    static get msgTypesAction()
    {
        return _msgTypesAction;
    }


    static get msgTypesSub()
    {
        return _msgTypesSub;
    }


    static get msgTypesAttributes()
    {
        return _msgTypesAttributes;
    }


    static get events()
    {
        return _events;
    }


    static get attributes()
    {
        return _eventAttributes;
    }

    // constructor()
    // {
    //     this.msgTypes           = WebMsgsModule.msgTypes;
    //     this.msgTypesInfo       = WebMsgsModule.msgTypesInfo;
    //     this.msgTypesAction     = WebMsgsModule.msgTypesAction;
    //     this.msgTypesSub        = WebMsgsModule.msgTypesSub;
    //     this.msgTypesAttributes = WebMsgsModule.msgTypesAttributes;

    //     this.events             = WebMsgsModule.events;
    //     this.attributes         = WebMsgsModule.eventAttributes;
    // }
}