'use strict'


import { Dateq }         from '../Dateq.class.mjs';

import { ElementState }  from './ElementState.class.mjs';
import { ElementStatus } from './ElementStatus.class.mjs';
import { ElementType }   from './ElementType.class.mjs';


const _attrs = {
    _createddate:     '_createddate',
    _date:            '_date',
    _description:     '_description',
    _id:              '_id',
    _lastupdateddate: '_lastupdateddate',
    _name:            '_name'
}; // _attrs


const _attrsIgnoreValid = [
    _attrs._createddate,
    _attrs._id,
    _attrs._lastupdateddate
]; // _attrsIgnoreValid


export class ElementCommon
{
    constructor(d, attrs)
    {
        this[ElementCommon.attrs._createddate] = Dateq.get(undefined, true);

        this._merge(d, attrs);
    }


    static get attrs()
    {
        return _attrs;
    }


    static get State()
    {
        return ElementState;
    }
    

    static get Status()
    {
        return ElementStatus;
    }


    static get Type()
    {
        return ElementType;
    }


    //
    // Getters and setters
    //
    get createdDate()
    {
        return this[ElementCommon.attrs._createddate];
    }

    set createdDateI(d)
    {
        this[ElementCommon.attrs._createddate] = Dateq.get(d, d ? false : true);
    }

    get createdDateStr()
    {
        return (this.createdDate instanceof Dateq) ? this.createdDate.displayStr : "";
    }


    get date()
    {
        return this[ElementCommon.attrs._date];
    }

    set dateI(d)
    {
        if (d) this[ElementCommon.attrs._date] = Dateq.get(d);
    }

    get dateStr()
    {
        return (this.date instanceof Dateq) ? this.date.displayStr : "";
    }


    get description()
    {
        return this[ElementCommon.attrs._description];
    }


    get id()
    {
        return this[ElementCommon.attrs._id];
    }


    get lastUpdatedDate()
    {
        return this[ElementCommon.attrs._lastupdateddate];
    }

    set lastUpdatedDateI(d)
    {
        return (this[ElementCommon.attrs._lastupdateddate] = Dateq.get(d, d ? false : true));
    }

    get lastUpdatedDateStr()
    {
        return this.lastUpdatedDate instanceof Dateq ? this.lastUpdatedDate.displayStr : "";
    }

    
    get name()
    {
        return this[ElementCommon.attrs._name];
    }


    //
    // Public methods
    //
    static isEmpty(d)
    {
        if (d) for (const k in d) {
            if (d.hasOwnProperty(k)) return false;
        } // for

        return true;
    }


    // Child objects free to override this
    isValid()
    {
        // Check if not empty, or, if so, that more than just the 'ignore' fields are set
        return (
            ! (Array.isArray(_attrsIgnoreValid)
                ? ElementCommon._isEmptyIgnoringAttrbutes(this, _attrsIgnoreValid)
                : ElementCommon._isEmpty(this)
            )
        );
    }

    
    static setAttrs2(o, d, a)
    {
        if (o && Object(a)) {
            Object.entries(Object(a)).forEach(([k, v]) => {
                // console.log(k + ", " + v + ", " + d[v])
                if (k != null && v != null) o[k] = ElementCommon._getAttr(d, v);
            }); // forEach
        }
        
        return o;
    }


    static _getAttr(d, t)
    {
        return d && t
            ? (d[t] != null
                ? d[t]
                : (d['_' + t] != null
                    ? d['_' + t]
                    : undefined
                )
            )

           : undefined;
    }
    
    
    //
    // Protected methods
    //
    static _getAttr(d, a)
    {
        return (d && a && a in Object(d)) ? Object(d)[a] : undefined;
    }


    static _isEmpty(d)
    {
        return d ? Object.values(Object(d)).every(v => ! v || v === null) : true;

        // if (d) for (const k in d) {
        //     if (d.hasOwnProperty(k)) return false;
        // } // for

        // return true;
    }


    static _isEmptyIgnoringAttrbute(d, a)
    {
        return Object.entries(Object(d)).every(([k, v]) => {
            return ! v || v === null || k === a
        }); // every
    }

    // Note: plural fn name of that above; NOT the same
    static _isEmptyIgnoringAttrbutes(d, a)
    {
        return Object.entries(Object(d)).every(([k, v]) => {
            return ! v || v === null || (Array.isArray(a) && a.includes(k))
        }); // every
    }

    
    // Used to convert to/from server/web client message
    _merge(d, attrsE, attrsI)
    {
        this._setAttrs2(d, attrsE ? attrsE : {...ElementCommon.attrs, ...attrsI});

        // Causes setters to run to convert to Dateq
        this.createdDateI     = this[ElementCommon.attrs._createddate];
        this.dateI            = this[ElementCommon.attrs._date];
        this.lastUpdatedDateI = this[ElementCommon.attrs._lastupdateddate];

        return this;
    }

    
    _setAttrs(d, a)
    {
        if (Array.isArray(a)) {
            a.forEach((v) => {
                if (v) this[v] = ElementCommon._getAttr(d, v);
            }); // forEach
        }
        
        return this;
    }


    _setAttrs2(d, a)
    {
        if (Object(a)) {
            Object.entries(Object(a)).forEach(([k, v]) => {
                const val = (k && v) ? ElementCommon._getAttr(d, v) : undefined;
                if (val !== undefined) this[k] = val;//ElementCommon._getAttr(d, v);
            }); // forEach
        }
        
        return this;
    }
}