'use strict';


import {
    DeviceCommon,
    DeviceTypes
}                 from './Device.class.mjs';


const _DeviceType = DeviceTypes.powersocket;


// Actual code shared between NodeJS and AngularJS
export class DevicePowerSocketCommon extends DeviceCommon
{
    constructor(d, attrs)
    {
        super(d, attrs);

        this._type = _DeviceType;

        console.log("[TBD] DevicePowerSocket");
    }


    //
    // Static methods
    //
    
    // Factory method
    // Override
    static get(d, attrs)
    {
        return d
            ? (d instanceof DevicePowerSocketCommon
                ? d
                : new DevicePowerSocketCommon(d, attrs)
              )
            : undefined;
    }
}