'use strict';

import { AttributesHelperCommon } from './Attributes/index.mjs';

import { ElementState }           from '../ElementState.class.mjs';
import { ElementStatus }          from '../ElementStatus.class.mjs';
import {
    MobileCellCommon,
    MobileCellHelperCommon
}                                 from '../MobileCell/index.mjs';
import { Notification }           from '../Notification/index.mjs';

import {
    DeviceCommon,
    DeviceTypes
}                                 from './Device.class.mjs';


export const DeviceRepeaterTypes = {
    go:         'go',
    quatra:     'quatra',
    quatra1000: 'quatra1000',
    quatra4000: 'quatra4000',
    solo:       'solo'
}; // DeviceRepeaterTypes
// export const _deviceTypes = DeviceRepeaterTypes;


const _attrs = {
    _antenna:       '_antenna',
    _cusnum:        '_cusnum',
    _mobilecells:   '_mobilecells',
    _operator:      '_operator',
    _sku:           '_sku'
}; // _attrs


export const DeviceRepeaterNotificationSeverity = {
    low:    'low',
    medium: 'medium',
    high:   'high',
    none:   'none'
}; // DeviceRepeaterNotificationSeverity


const _DeviceType = DeviceTypes.repeater;


export class DeviceRepeaterCommon extends DeviceCommon
{
    constructor(d, attrs)
    {
        super(d, attrs);

        this._type = _DeviceType;
        // //this._capabilityPowerControl = undefined;
    }


    //
    // Static methods
    //
    
    // Factory method
    // Override
    static get(d, attrs)
    {
        return d
            ? (d instanceof DeviceRepeaterCommon
                ? d
                : new DeviceRepeaterCommon(d, attrs)
              )
            : undefined;
    }


    static get attrs()
    {
        return _attrs;
    }


    static get NotificationSeverity()
    {
        // return Notification.NotificationSeverity;
    }


    //
    // Getters and setters
    //
    get antenna()
    {
        return this[DeviceRepeaterCommon.attrs._antenna];
    }

    set antenna(d)
    {
        this[DeviceRepeaterCommon.attrs._antenna] = d;
    }


    get cusnum()
    {
        return this[DeviceRepeaterCommon.attrs._cusnum];
    }

    set cusnum(d)
    {
        this[DeviceRepeaterCommon.attrs._cusnum] = d;
    }


    get mobilecells()
    {
        return this[DeviceRepeaterCommon.attrs._mobilecells];
    }

    set mobilecells(d)
    {
        this[DeviceRepeaterCommon.attrs._mobilecells] = DeviceRepeaterCommon._processMobileCells(d);
    }


    // Override
    get modelName()
    {
        return super.modelName ? super.modelName : this.type;
    }


    get operator()
    {
        return this[DeviceRepeaterCommon.attrs._operator];
    }

    set operator(d)
    {
        d = d ? d.replace(/\(.*\)/gi, '') : d; // targeted at replacing '(3g & 4g)' string sent by Nextivity
        if (d) this[DeviceRepeaterCommon.attrs._operator] = NetworksCommon.getOperator(undefined, d.trim());
    }


    get sku()
    {
        return this[DeviceRepeaterCommon.attrs._sku];
    }

    set sku(d)
    {
        this[DeviceRepeaterCommon.attrs._sku] = d;
    }

    
    //
    // Public methods
    //
    processMobileCell(d)
    {
        const o = DeviceRepeaterCommon._processMobileCell(d);
        if (o) {
            if (! this.mobilecells) this[DeviceRepeaterCommon.attrs._mobilecells] = {};
            this.mobilecells[o.id] = o; // will overwrite if ID is already present
        }

        return o;
    }


    // Override
    processNotification(d)
    {
        const n = super.processNotification(d);

        if (n instanceof Notification) {
            // Extract SKU if present, as this is really an attribute of the system - data soruced from Nextivity FTP only
            if (! this.sku) this.sku = n.sku;            
        }
    }


    // Override
    setState(d)
    {
        super.setState();

        let s;
        if (this.status instanceof ElementStatus) {
            if (ElementStatus.Offline !== this.status) {
                switch (d) {
                    case DeviceRepeaterNotificationSeverity.high:
                        s = ElementState.Poor;
                    break;

                    case DeviceRepeaterNotificationSeverity.medium:
                    // Fall through
                    case DeviceRepeaterNotificationSeverity.low:
                        s = ElementState.Average;
                    break;

                    case DeviceRepeaterNotificationSeverity.none:
                    // Fall throuh
                    default:
                        s = ElementState.Good;
                } // switch
            }
            else {
                // Special case for Nextivity Solo/GO, as these devices
                // are normally offline due to their usual need for Bluetooth
                // to connect to OAM.  So, offline isn't necessarily as serious

                const name = this.modelName;
                if (typeof name === 'string'
                    && (DeviceRepeaterTypes.go === name.toLowerCase()
                        || DeviceRepeaterTypes.solo === name.toLowerCase()) )
                {
                    s = ElementState.Average;
                }
            }

            if (s instanceof ElementState && ElementState.Unknown !== s) this.state = s;
            return this.state;
        }
    }


    //
    // Private methods
    //

    // Override
    _merge(d, attrs)
    {
        super._merge(d, attrs, attrs ? undefined : DeviceRepeaterCommon.attrs);

        // Set mobile cells
        this.mobilecells =
            (d)
                ? AttributesHelperCommon.convertObjectArray(d, DeviceRepeaterCommon.attrs._mobilecells, MobileCellHelperCommon.field)
                : [];

        return this;
    }


    static _processMobileCell(d)
    {
        return d ? MobileCellHelperCommon.get(d) : undefined; // handles if d already MobileCellCommon
    }


    static _processMobileCells(d)
    {
        // Convert to objects
        const o = {};
        if (Array.isArray(d)) d.forEach((e) => {
            const m = DeviceRepeaterCommon._processMobileCell(e);
            // if (m && m[MobileCellCommon.attrs._id]) o[m[MobileCellCommon.attrs._id]] = m;
            if (m instanceof MobileCellCommon && m.id) o[m.id] = m;
        }); // forEach

        return o;
    }
}