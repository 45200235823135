'use strict'


// import { default as networkData } from './networks.json';

// Needed from Node v16.14.x, not supported by Angular/WebPack
// so will need to find some mechanism to differentiate
// import { default as networkData } from './networks.json' assert { type: 'json' };

let _obj = { };
// import('./networks.json')
//     .then((v) => {
// console.log("Hello22");
// console.log(v);
// console.log(JSON.stringify(v));
//     })
//     .catch((e) => {
//         console.log("Hello 23");
//     })


const _mccLength     = 3;
const _minPlmnLength = 5;
const _rssiMin       = -114;


export class MobileNetworks
{
    constructor()
    {
        this._obj = _obj;
    }


    static set(d)
    {
        _obj = d;
    }


    //
    // Getters
    //
    static get mccLength()
    {
        return _mccLength;
    }


    static get minPlmnLength()
    {
        return _minPlmnLength;
    }


    static get rssiMin() 
    {
        return _rssiMin;
    }

    
    //
    // Public methods
    //
    static getMcc(plmn)
    {
        // var mcc;
    
        // if (plmn && plmn.length >= MobileNetworksCommon.mccLength) {
        //     mcc = plmn.substring(0, MobileNetworksCommon.mccLength);
        // }
    
        //return mcc;
        return this._getMccOrMnc(plmn, false);
    }
    
    
    static getMnc(plmn)
    {
        // var mcc;
    
        // if (plmn && plmn.length >= MobileNetworksCommon.minPlmnLength) {
        //     mcc = plmn.substring(MobileNetworksCommon.mccLength, plmn.length - 1);
        // }
        
        //return mnc;
        return this._getMccOrMnc(plmn, true);
    }
    

    //
    // Static methods
    //
    static getOperator(plmn, operator, country)
    {
        return (
            _obj
            ? (_obj.networks[operator]
               ? operator
               : Object.keys(_obj.networks).find((d) => {
                   return _obj.networks[d]
                       && Array.isArray(_obj.networks[d])
                       && _obj.networks[d].includes(operator)
               }) || operator
              )
            : operator
        );

        //     if (operator && mcc) {
        //     //switch (country) {
        //     //    case 'gb':
        //     switch (mcc) {
        //         case '234': // gb
        //             switch (operator) {
        //                 case '3':
        //                 case '3 uk':
        //                 case '3 mobile':
        //                 case 'three':
        //                     newOperator = "3";
        //                     break;
    
        //                 case 'bt':
        //                 case 'bt mobile':
        //                 case 'orange':
        //                 case 'orange uk':
        //                 case 'ee':
        //                 case 'ee uk':
        //                 case 'plusnet':
        //                 case 'plus.net':
        //                 case 't-mobile':
        //                 case 't-mobile uk':
        //                     newOperator = "ee";
        //                     break;
    
        //                 case 'o2':
        //                 case 'o2 - uk':
        //                     newOperator = "o2";
        //                     break;
    
        //                 case 'virgin':
        //                 case 'virgin media':
        //                     newOperator = "virgin";
        //                     break;
    
        //                 case 'vodafone':
        //                 case 'vodafone uk':
        //                     newOperator = "vodafone";
        //                     break;
    
        //                 default:
        //                     newOperator = operator;
        //             } // switch operator
    
        //             break;
        //         default:
        //             switch (operator) {
        //                 case '3 ita':
        //                     newOperator = "3";
        //                     break;
    
        //                 case 'vodafone qatar':
        //                     newOperator = "vodafone";
        //                     break;
    
        //                 default:
        //                    // No MCC or operator available
        //                   newOperator = operator;
        //             }
        //     } // switch MCC
        // }
    
        // return newOperator;
    }  


    //
    // Private methods
    //
    static _getMccOrMnc(plmn, mnc)
    {
        let val = undefined;
    
        if (plmn && plmn.length >= MobileNetworksCommon.mccLength) {
            val = plmn.substring(0, MobileNetworksCommon.mccLength);
    
            // Get MNC if requested, else send MCC
            if (mnc && plmn.length > MobileNetworksCommon.minPlmnLength) {
                val = plmn.substring(MobileNetworksCommon.mccLength);
                if (val.length < MobileNetworksCommon.mccLength) val = '0' + val;
            }
        }
    
        return val;
    }
}