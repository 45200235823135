'use strict';


// import { DeviceMsgsModule } from '../../../messages/index.mjs';
import { ElementCommon } from '../../Element.class.mjs';
import { ElementType }   from '../../ElementType.class.mjs';


// 2020-05-29
// Can't be static class objects in ES2016, not supported until ES?, need to wait for browser support


const _powerState = {
    off:     'off',
    on:      'on',
    status:  'status',
    reset:   'reset',
    unknown: 'unknown'
}; // _powerState


const _attrs = {
    _batterycapacity:            '_batterycapacity',
    _batterychargecounter:       '_batterychargecounter',
    _batterychargetimeremaining: '_batterychargetimeremaining',
    _batterycharging:            '_batterycharging',
    _batteryconnected:           '_batteryconnected',
    _batterycurrentaverage:      '_batterycurrentaverage',
    _batterycurrentnow:          '_batterycurrentnow',
    _batteryenergycounter:       '_batteryenergycounter',
    _batteryhealth:              '_batteryhealth',
    _batterylevel:               '_batterylevel',
    _batterylow:                 '_batterylow',
    _batterypresent:             '_batterypresent',
    _batteryscale:               '_batteryscale',
    _batterystatus:              '_batterystatus',
    _batterytechnology:          '_batterytechnology',
    _batterytemperature:         '_batterytemperature',
    _batteryvoltage:             '_batteryvoltage'
}; //_attrs


// const _da                     = DeviceMsgsModule.attributes;
const _field = ElementType.Power;//_da.power;


const _batteryCurrentDp       = 2;
const _batteryLevelDp         = 0;
const _batteryCurrentMilliAmp = 1000;


export class PowerCommon extends ElementCommon
{
    // Factory method
    static get(d, attrs)
    {
        return d
            ? (d instanceof PowerCommon
                ? d
                : new PowerCommon(d, attrs)
            )
            : undefined;
    }


    static get attrs()
    {
        return _attrs;
    }


    static get field()
    {
        return _field;
    }


    get batteryCapacity()
    {
        return this[PowerCommon.attrs._batterycapacity];
    }


    get batteryChargeCounter()
    {
        return this[PowerCommon.attrs._batterychargecounter];
    }


    get batteryChargeTimeRemaining()
    {
        return this[PowerCommon.attrs._batterychargetimeremaining];
    }

    
    get batteryCharging()
    {
        return !!this[PowerCommon.attrs._batterycharging];
    }


    get batteryConnected()
    {
        return this.batteryConnectedI
                && "unknown" !== this.batteryConnectedI
                && 'none'    !== this.batteryConnectedI
            ? this.batteryConnectedI
            : undefined;
    }
    
    get batteryConnectedI()
    {
        return this[PowerCommon.attrs._batteryconnected];
    }


    get batteryCurrentAverage()
    {
        return Number(this[PowerCommon.attrs._batterycurrentaverage]);
    }

    get batteryCurrentAverageStr()
    {
        // Need to ensure is Number; sometimes is a String and isFinite won't work on that
        return ((! Number.isNaN(this.batteryCurrentAverage)) && Number.isFinite(this.batteryCurrentAverage))
            ? this.batteryCurrentAverage.toFixed(_batteryCurrentDp)
            : undefined;
    }


    get batteryCurrentNow()
    {
        return Number(this[PowerCommon.attrs._batterycurrentnow]);
    }

    get batteryCurrentNowStr()
    {
        // Need to ensure is Number; sometimes is a String and isFinite won't work on that
        return ((! Number.isNaN(this.batteryCurrentNow)) && Number.isFinite(this.batteryCurrentNow))
            ? this.batteryCurrentNow.toFixed(_batteryCurrentDp)
            : undefined;
    }


    get batteryEnergyCounter()
    {
        return this[PowerCommon.attrs._batteryenergycounter];
    }


    get batteryHealth()
    {
        return this[PowerCommon.attrs._batteryhealth];
    }


    get batteryLevel()
    {
        return Number(this[PowerCommon.attrs._batterylevel]);
    }


    get batteryLevelStr()
    {
        // Need to ensure is Number; sometimes is a String and isFinite won't work on that
        return ((! Number.isNaN(this.batteryLevel)) && Number.isFinite(this.batteryLevel))
            ? this.batteryLevel.toFixed(_batteryLevelDp)
            : undefined;
    }


    get batteryLow()
    {
        return !!this[PowerCommon.attrs._batterylow];
    }


    get batteryPresent()
    {
        return !!this[PowerCommon.attrs._batterypresent];
    }


    get batteryScale()
    {
        return this[PowerCommon.attrs._batteryscale];
    }


    get batteryStatus()
    {
        return this[PowerCommon.attrs._batterystatus];
    }


    get batteryTechnology()
    {
        return this[PowerCommon.attrs._batterytechnology];
    }


    get batteryTemperature()
    {
        return this[PowerCommon.attrs._batterytemperature];
    }


    get batteryVoltage()
    {
        return this[PowerCommon.attrs._batteryvoltage];
    }


    //
    // Protected methods
    //
    
    // Override
    // _merge(d, attrsE, attrsI)
    // {
    //     return super._merge(d, attrsE, {..._attrs, ...attrsI});
    // }
    _merge(d, attrsE, attrsI)
    {
        super._merge(d, attrsE, {..._attrs, ...attrsI});
  
        if (this.batteryCurrentAverage > _batteryCurrentMilliAmp
                || (this.batteryCurrentAverage < 0 && (this.batteryCurrentAverage * -1) > _batteryCurrentMilliAmp)) {
            this[PowerCommon.attrs._batterycurrentaverage] = this.batteryCurrentAverage / _batteryCurrentMilliAmp;
        }

        if (this.batteryCurrentNow > _batteryCurrentMilliAmp
                || (this.batteryCurrentNow < 0 && (this.batteryCurrentNow * -1) > _batteryCurrentMilliAmp)) {
            this[PowerCommon.attrs._batterycurrentnow] = this.batteryCurrentNow / _batteryCurrentMilliAmp;
        }

        return this;
    }
}