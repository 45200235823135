'use strict'


import { Dateq }         from '../../index.mjs';
// import { DeviceMsgsModule } from '../../messages/index.mjs';
import { ElementCommon } from '../Element.class.mjs';
import { ElementType }   from '../ElementType.class.mjs';


const _attrs = {
    _id:   ElementCommon.attrs._id,
    _name: ElementCommon.attrs._name,
};


const _field = ElementType.TestProfile;

const _typeField = _attrs._type;


export class TestProfile extends ElementCommon
{
    static get(d, attrs)
    {
        return d
            ? (d instanceof TestProfile
                ? d
                : new TestProfile(d, attrs)
            )
            
            : undefined;
    }

   
    static get attrs()
    {
        return _attrs;
    }


    static get typeField()
    {
        return _typeField;
    }


    get type()
    {
        return this[TestProfile.attrs._type];
    }
    

    _merge(d, attrsE, attrsI)
    {
        return super._merge(d, attrsE, {..._attrs, ...attrsI});
    }
}