'use strict';


// import { DeviceMsgsModule } from '../../../../messages/index.mjs';
import { ElementCommon } from '../../../Element.class.mjs';
import { ElementType }   from '../../../ElementType.class.mjs';


const _attrs = {
    _connectiontype: '_connectiontype',
    _ipaddrdst:      '_ipaddrdst',
    _ipaddrsrc:      '_ipaddrsrc',
    _ipportdst:      '_ipportdst',
    _ipportSrc:      '_ipportSrc',
    _pid:            '_pid',
    _reconnect:      '_reconnect',
    _state:          '_state'
}; // _attrs


// const _da    = DeviceMsgsModule.attributes;
const _field = ElementType.Connection;//_da.connection;


export class ConnectionCommon extends ElementCommon
{

    static Connect    = "connect";
    static Disconnect = "disconnect";
    static Reconnect  = "reconnect";


    // Factory method
    static get(d, attrs)
    {
        return d
            ? (d instanceof ConnectionCommon
                ? d
                : new ConnectionCommon(d, attrs)
            )
            : undefined;
    }


    static get attrs()
    {
        return _attrs;
    }


    static get field()
    {
        return _field;
    }


    get ipAddrDst()
    {
        return this[ConnectionCommon.attrs._ipaddrdst];
    }


    get ipAddrSrc()
    {
        return this[ConnectionCommon.attrs._ipaddrsrc];
    }


    get ipPortDst()
    {
        return this[ConnectionCommon.attrs._ipportdst];
    }


    get ipPortSrc()
    {
        return this[ConnectionCommon.attrs._ipportSrc];
    }


    get pid()
    {
        return this[ConnectionCommon.attrs._pid];
    }


    get reconnect()
    {
        return !!(this[ConnectionCommon.attrs._reconnect]);
    }


    get state()
    {
        return this[ConnectionCommon.attrs._state];
    }


    get type()
    {
        return this[ConnectionCommon.attrs._connectiontype];
    }


    //
    // Protected methods
    //
    
    // Override
    _merge(d, attrsE, attrsI)
    {
        super._merge(d, attrsE, {..._attrs, ...attrsI});
    }
    // {
    //     return super._merge(d, attrs, attrs ? undefined : ConnectionCommon.attrs);
    // }
}