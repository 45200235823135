'use strict';


// import { DeviceMsgsModule } from '../../../messages/index.mjs';
import { ElementCommon } from '../../Element.class.mjs';
import { ElementType }   from '../../ElementType.class.mjs';


const _attrs = {
    _dataroaming:         '_dataroaming',
    _icontint:            '_icontint',
    _imei:                '_imei',
    _imeisv:              '_imeisv',
    _imsi:                '_imsi',
    _isroaming:           '_isroaming',
    _msisdn:              '_msisdn',
    _simoperator:         '_simoperator',
    _simoperator_country: '_simoperator_country',
    _simplmn:             '_simplmn',
    _simserialnumber:     '_simserialnumber',
    _simslotnumber:       '_simslotnumber',
    _simstate:            '_simstate',
    _imsicustom:          '_imsicustom',
    _imsicustom2:         '_imsicustom2',
    _msisdncustom:        '_msisdncustom',
    _msisdncustom2:       '_msisdncustom2',
};


// const _da    = DeviceMsgsModule.attributes;
const _field = ElementType.Sim;//_da.sim;


export class SimCommon extends ElementCommon
{
    static get(d, attrs)
    {
        return d
            ? (d instanceof SimCommon
                ? d
                : new SimCommon(d, attrs)
            )
            : undefined;
    }



    static get attrs()
    {
        return _attrs;
    }


    static get field()
    {
        return _field;
    }


    get dataroaming()
    {
        return this[SimCommon.attrs._dataroaming];
    }

    get icontint()
    {
        return this[SimCommon.attrs._icontint];
    }


    get imei()
    {
        return this[SimCommon.attrs._imei];
    }


    get imeisv()
    {
        return this[SimCommon.attrs._imeisv];
    }


    get imsi()
    {
        return this[SimCommon.attrs._imsi];
    }

    get isRoaming()
    {
        return this[SimCommon.attrs._isroaming];
    }


    get msisdn()
    {
        return this[SimCommon.attrs._msisdn];
    }


    get operator()
    {
        return this[SimCommon.attrs._simoperator];
    }


    get operatorCountry()
    {
        return this[SimCommon.attrs._simoperator_country];
    }


    get plmn()
    {
        return this[SimCommon.attrs._simplmn];
    }


    get serialnumber()
    {
        return this[SimCommon.attrs._simserialnumber];
    }


    get slot()
    {
        return this[SimCommon.attrs._simslotnumber];
    }


    get state()
    {
        return this[SimCommon.attrs._simstate];
    }


    get imsicustom()
    {
        return this[SimCommon.attrs._imsicustom];
    }


    get imsicustom2()
    {
        return this[SimCommon.attrs._imsicustom2];
    }


    get msisdncustom()
    {
        return this[SimCommon.attrs._msisdncustom];
    }


    get msisdncustom2()
    {
        return this[SimCommon.attrs._msisdncustom2];
    }
    

    //
    // Protected methods
    //

    // Override
    // _merge(d, attrsE, attrsI)
    // {
    //     return super._merge(d, attrsE, {..._attrs, ...attrsI});
    // }
    _merge(d, attrs)
    {
        return super._merge(d, attrs, attrs ? undefined : SimCommon.attrs);
    }
}