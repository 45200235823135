'use strict'


import {
    Kpi,
    // KpiTypes
}                                      from './Kpi.class.mjs';
import { KpiMobileTechnologyOverTime } from './index.mjs';


const _typeField = Kpi.typeField;


export class KpiHelper
{
    // Factory method
    static get(d, attrs)
    {
        if (d instanceof KpiHelper) return d;

        let fn = undefined;
        if (d) {
            switch (KpiHelper._getType(d)) {
                case Kpi.types.mobileTechnologyOverTime:
                    fn = KpiMobileTechnologyOverTime.get
                break;
            } // switch
        }

        return fn instanceof Function
            ? fn(d, attrs) 
            : undefined;
    }


    static get typeField()
    {
        return _typeField;
    }


    //
    // Private methods
    //
    static _getType(d)
    {
        return d && _typeField && _typeField in Object(d) ? Object(d)[_typeField] : undefined;
    }
}