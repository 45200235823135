'use strict'


import { MobileCellCommon } from './MobileCell.class.mjs';


const _attrs = {
    _ber:   '_ber',
    _ecno:  '_ecno',
    _psc:   '_psc',
    _rncid: '_rncid',
    _rscp:  '_rscp'
}


const _cidMask = 0xffff;


export class MobileCellUmtsCommon extends MobileCellCommon
{
    static get(d, attrs)
    {
        return new MobileCellUmtsCommon(d, attrs);
    }


    static get attrs()
    {
        return _attrs;
    }

    
    //
    // Getters and setters
    //

    // Override
    get cellcode()
    {
        return this.psc;
    }

    
    // Override
    get signalquality()
    {
        return this.ecno;
    }

    // Override
    get signalqualitycategory()
    {
        // Keep in sync with Android \java\com\spryfoxnetworks\quantumpath\cellular\QPMobileCellUmts.java#getSigQualityCategory

        if (this.signalquality !== null) {
            if      (this.signalquality > 0)     return MobileCellCommon.signalCategories.Invalid

            else if (this.signalquality >= -6)   return MobileCellCommon.signalCategories.Great;
            else if (this.signalquality >= -10)  return MobileCellCommon.signalCategories.Good;
            else if (this.signalquality >= -15)  return MobileCellCommon.signalCategories.Average;
            else if (this.signalquality >= -20)  return MobileCellCommon.signalCategories.Poor;
        }

        return MobileCellCommon.signalCategories.Bad;
    }


    // Override
    get signalstrength()
    {
        return this.rscp;
    }

    // Override
    get signalstrengthcategory()
    {
        // Keep in sync with Android \java\com\spryfoxnetworks\quantumpath\cellular\QPMobileCellUmts.java#getSigStrengthCategory
        if (this.signalstrength !== null) {
            if      (this.signalstrength == 0)    return MobileCellCommon.signalCategories.Invalid

            else if (this.signalstrength >= -85)  return MobileCellCommon.signalCategories.Great;
            else if (this.signalstrength >= -95)  return MobileCellCommon.signalCategories.Good;
            else if (this.signalstrength >= -105) return MobileCellCommon.signalCategories.Average;
            else if (this.signalstrength >= -115) return MobileCellCommon.signalCategories.Poor;
        }

        return MobileCellCommon.signalCategories.Bad;
    }


    get ber()
    {
        return this[MobileCellUmtsCommon.attrs._ber];
    }


    get ecno()
    {
        return this[MobileCellUmtsCommon.attrs._ecno];
    }


    get psc()
    {
        return this[MobileCellUmtsCommon.attrs._psc];
    }


    get rncid()
    {
        return this[MobileCellUmtsCommon.attrs._rncid];
    }


    get rscp()
    {
        return this[MobileCellUmtsCommon.attrs._rscp];
    }


    //
    // Public methods
    //
    static gcidToLcid(cid)
    {
        return cid & this._cidMask;
    }


    static gcidToRcid(cid)
    {
        return (this.gcid >> 16) & this.cidMask;
    }


    static idsToGcid(rncId, lcid)
    {
        return ((rncId << 16) & this._cidMask) + lcid;
    }


    // const cid = 417026
    // const lcid = 2
    // const rncId = 1629
    // console.log(cid + ": " + lcid + " + " + enbId);
    // console.log(MobileCellUmtsCommon.gcidToLcid(cid));
    // console.log(MobileCellUmtsCommon.gcidToRcid(cid));
    // console.log(MobileCellUmtsCommon.idsToGcid(rncId, lcid));


    //
    // Protected methods
    //
    _merge(d, attrs)
    {
        super._merge(d, attrs, attrs ? undefined : MobileCellUmtsCommon.attrs);

        // If both cellId and ecid are populated, then this is a Nextivity repeater
        // cell and cellId is really lcid.  Swap and calcuate cellId
        if (this.cellId) {
            if (this._ecid && ! this._lcid) {
                this._cellId = MobileCellLteCommon.idsToGcid(this._ecid, this._lcid = this.cellId);
            }
            else {
                this._ecid  = MobileCellLteCommon.gcidToLcid(this.cellId);
                this._lcid  = MobileCellLteCommon.gcidToEcid(this.cellId);
            }
        }

        return this;
    }
}