'use strict'


import { ElementStatus } from '../ElementStatus.class.mjs';


export class DeviceGroupStatus extends ElementStatus
{
    static Paused  = new DeviceGroupStatus(ElementStatus.types.paused);
    static Started = new DeviceGroupStatus(ElementStatus.types.started);
    static Stopped = new DeviceGroupStatus(ElementStatus.types.stopped);
    static Unknown = new DeviceGroupStatus(ElementStatus.types.unknown);


    constructor(d)
    {
        super(d);
    }


    // Override
    toString()
    {
        return "DeviceGroupStatus: " + this.status;
    }
}