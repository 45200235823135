'use strict';


import { Kpi }              from './Kpi.class.mjs';
import { MobileCellCommon } from '../MobileCell/index.mjs';


const _attrs = {
    [MobileCellCommon.attrs._technology]: MobileCellCommon.attrs._technology,
    _totalsightings:                      '_totalsightings',
    _totaltime:                           '_totaltime'
};


const _typeField = Kpi.types.mobileTechnologyOverTime;


export class KpiMobileTechnologyOverTime extends Kpi
{
    // Factory method
    static get(d, attrs)
    {
        return d
            ? (d instanceof KpiMobileTechnologyOverTime
                ? d
                : new KpiMobileTechnologyOverTime(d, attrs)
              )
            : undefined;
    }

    
    static get attrs()
    {
        return _attrs;
    }


    static get typeField()
    {
        return _typeField;
    }
    

    //
    // Getters and setters
    //
    get technology()
    {
        return this[KpiMobileTechnologyOverTime.attrs[MobileCellCommon.attrs._technology]];
    }


    get totalsightings()
    {
        return this[KpiMobileTechnologyOverTime.attrs._totalsightings];
    }

    set totalsightings(d)
    {
        return (this[KpiMobileTechnologyOverTime.attrs._totalsightings] = d);
    }


    get totaltime()
    {
        return this[KpiMobileTechnologyOverTime.attrs._totaltime];
    }

    set totaltime(d)
    {
        return (this[KpiMobileTechnologyOverTime.attrs._totaltime] = d);
    }


    //
    // Public methods
    //
    add(technology, time)
    {
        console.debug("Stu kpi: " + technology + ", " + time)
        if (! (technology in MobileCellCommon.types)) {
            console.warn("Unknown mobile technology: " + technology + ", " + time);
        }

        if (! (technology in this.technology)) {
            this.technology[technology] = {
                [KpiMobileTechnologyOverTime.attrs._totalsightings]: 0,
                [KpiMobileTechnologyOverTime.attrs._totaltime]:      0,
            };
        }

        if (time >= 0) {
            this.technology[technology][KpiMobileTechnologyOverTime.attrs._totalsightings]++;
            this.technology[technology][KpiMobileTechnologyOverTime.attrs._totaltime] += time;

            // this.totalsightings++;
            this.totaltime += time;
        }

        return this.technology[technology];
    }


    //
    // Protected methods
    //
    _merge(d, attrs)
    {
        console.error("STU KPI 987");
        console.error(d)
        console.error(JSON.stringify(d))

        if (d) super._merge(d, attrs, attrs ? undefined : KpiMobileTechnologyOverTime.attrs);

        if (! this[KpiMobileTechnologyOverTime.attrs._totalsightings])
            this[KpiMobileTechnologyOverTime.attrs._totalsightings] = 0;

        if (! this[KpiMobileTechnologyOverTime.attrs._totaltime])
            this[KpiMobileTechnologyOverTime.attrs._totaltime] = 0;
        
        if (! this[KpiMobileTechnologyOverTime.attrs._technology]) {
            this[KpiMobileTechnologyOverTime.attrs._technology] = {};
            Object.values(MobileCellCommon.types).forEach((v) => this.add(v, -1) ); // forEach
        }

        console.error(JSON.stringify(this));

        return this;
    }
}