'use strict';


// import { DeviceMsgsModule }  from '../../messages/index.mjs';
import { ElementCommon } from '../Element.class.mjs';
import { ElementType }   from '../ElementType.class.mjs';


const _attrs = {
    _country:  '_country',
    _id:       ElementCommon.attrs._id,
    _mcc:      '_mcc',
    _mnc:      '_mnc',
    _operator: '_operator',
    _plmn:     '_plmn'
};


// const _da    = DeviceMsgsModule.attributes;
// const _dv    = DeviceMsgsModule.values;
const _field = ElementType.Mobilenetwork;//_da.mobilenetwork;


export class MobileNetworkCommon extends ElementCommon
{
    static get(d, attrs)
    {
        return d
            ? (d instanceof MobileNetworkCommon
                ? d
                : new MobileNetworkCommon(d, attrs)
            )

            : undefined;
    }


    static get attrs()
    {
        return _attrs;
    }


    static get field()
    {
        return _field;
    }
    

    //
    // Getters and setters
    //
    get country()
    {
        return this[MobileNetworkCommon.attrs._country];
    }


    get mcc()
    {
        return this[MobileNetworkCommon.attrs._mcc];
    }


    get mnc()
    {
        return this[MobileNetworkCommon.attrs._mnc];
    }


    get operator()
    {
        return this[MobileNetworkCommon.attrs._operator];
    }


    get plmn()
    {
        return this[MobileNetworkCommon.attrs._plmn]
            ? this[MobileNetworkCommon.attrs._plmn]
            : (this.mcc
                   ? "" + this.mcc + (this.mnc < 10 ? '0' : '') + this.mnc
                   : undefined);
    }


    //
    // Protected methods
    //
    _merge(d, attrsE, attrsI)
    {
        return super._merge(d, attrsE, {...MobileNetworkCommon.attrs, ...attrsI});
    }
}