'use strict'


import { MobileCellCommon } from './MobileCell.class.mjs';


const _attrs = {
    _bsic:   '_bsic',
    _rxqual: '_rxqual'
}


export class MobileCellGsmCommon extends MobileCellCommon
{
    static get(d, attrs)
    {
        return new MobileCellGsmCommon(d, attrs);
    }


    static get attrs()
    {
        return _attrs;
    }


    //
    // Getters and setters
    //
    
    // Override
    get signalquality()
    {
        return this.rxqual;
    }

    // Override
    get signalqualitycategory()
    {
        return this.signalstrengthcategory;// MobileCellCommon.signalCategories.Good;//Invalid;
    }


    // Override
    get signalstrengthcategory()
    {
        // Keep in sync with Android \java\com\spryfoxnetworks\quantumpath\cellular\QPMobileCellGsm.java#getSigStrengthCategory
        if (this.signalstrength !== null) {
            if      (this.signalstrength == 0)    return MobileCellCommon.signalCategories.Invalid

            else if (this.signalstrength >= -89)  return MobileCellCommon.signalCategories.Great;
            else if (this.signalstrength >= -97)  return MobileCellCommon.signalCategories.Good;
            else if (this.signalstrength >= -103) return MobileCellCommon.signalCategories.Average;
            else if (this.signalstrength >= -107) return MobileCellCommon.signalCategories.Poor;
        }
        
        return MobileCellCommon.signalCategories.Bad;
    }
    

    get bsic()
    {
        return this[MobileCellGsmCommon.attrs._bsic];
    }


    get rxqual()
    {
        return this[MobileCellGsmCommon.attrs._rxqual];
    }


    //
    // Protected methods
    //
    _merge(d, attrs)
    {
        return super._merge(d, attrs, attrs ? undefined : MobileCellGsmCommon.attrs);
    }
}