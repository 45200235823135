'use strict'


import { Dateq }                  from '../../index.mjs';
import { ElementCommon }          from '../Element.class.mjs';
import { ElementHelperCommon }    from '../Element-helper.class.mjs';
import { ElementType }            from '../ElementType.class.mjs';
import { MobileCellHelperCommon } from '../MobileCell/index.mjs';
import { TestHelper }             from '../Tests/index.mjs';
import { WifiCell }               from '../WifiCell/index.mjs';

import { Survey }                 from './Survey.class.mjs';


const _attrs = {
    _end:                      '_end',
    _cell:                     '_cell',
    [ElementCommon.attrs._id]: ElementCommon.attrs._id,
    _mobilecells:              '_mobilecells',
    _start:                    '_start',
    _tests:                    '_tests'
}; // _attrs


const _field = ElementType.Survey;


export class SurveySighting extends ElementCommon
{
    static get(d, attrs)
    {
        return d
            ? (d instanceof SurveySighting
                ? d
                : new SurveySighting(d, attrs)
            )
            
            : undefined;
    }


    static get field()
    {
        return _field;
    }


    static get attrs()
    {
        return _attrs;
    }


    //
    // Getters and setters
    //
    get cell()
    {
        return this[SurveySighting.attrs._cell];
    }


    get endI()
    {
        return this[SurveySighting.attrs._end];
    }

    get end()
    {
        if (this.endI instanceof Dateq) return this.endI.displayStr;
    }


      // [TBD] - needed for Web to build
    get id()
    {
        return this._id;
    }


    get mobilecells()
    {
        return this[SurveySighting.attrs._mobilecells];
    }



    get startI()
    {
        return this[SurveySighting.attrs._start];
    }
    
    get start()
    {
        if (this.startI instanceof Dateq) return this.startI.displayStr;
    }


    get tests()
    {
        return this[SurveySighting.attrs._tests];
    }

    set tests(t)
    {
        this[SurveySighting.attrs._tests] = t;
    }


    //
    // Protected methods
    //
    _merge(d, attrsE, attrsI)
    {
        // this._setAttrs2(d, attrsE ? attrsE : {..._attrs, ...attrsI}); // [TBD]
        super._merge(d, attrsE, {..._attrs, ...attrsI});

        if (this[SurveySighting.attrs._start]) this[SurveySighting.attrs._start] = Dateq.get(this[SurveySighting.attrs._start]);
        if (this[SurveySighting.attrs._end])   this[SurveySighting.attrs._end]   = Dateq.get(this[SurveySighting.attrs._end]);

        this[SurveySighting.attrs._cell] = d ? MobileCellHelperCommon.get(d[SurveySighting.attrs._cell]) : undefined;
        if (! this[SurveySighting.attrs._cell])
            this[SurveySighting.attrs._cell] = d ? WifiCell.get(d[SurveySighting.attrs._cell]) : undefined;

        // Below will all create empty [] if d is not valid or no such elements exist in d
        this[SurveySighting.attrs._tests] = Survey.process2(d, SurveySighting.attrs._tests, TestHelper.get);

        return this;
    }
}