'use strict'


import { Dateq }                           from '../../index.mjs';
import {
    DeviceCommon,
    DeviceHelper
}                                          from '../Devices/index.mjs';
import { ElementCommon }                   from '../Element.class.mjs';
import { ElementHelperCommon }             from '../Element-helper.class.mjs';
import { ElementMainCommon }               from '../ElementMain.class.mjs';
import { ElementMainWithDeviceListCommon } from '../ElementMainWithDeviceList.class.mjs';
import { ElementState }                    from '../ElementState.class.mjs';
import { ElementType }                     from '../ElementType.class.mjs';

import { DeviceGroupStatus }               from './DeviceGroupStatus.class.mjs';


const _attrs = {
    [ElementCommon.attrs._description]: ElementCommon.attrs._description,
    _deviceid:                          '_deviceid',
    _end:                               '_end',
    _geolocs:                           '_geolocs',
    [ElementCommon.attrs._id]:          ElementCommon.attrs._id,
    _instanceid:                        '_instanceid',
    _kpis:                              '_kpis',
    [ElementCommon.attrs._name]:        ElementCommon.attrs._name,
    _mobilecells:                       '_mobilecells',
    _online:                            '_online', // if all included devices are online
    _pause:                             '_pause',
    _pointid:                           '_pointid',
    _pointname:                         '_pointname',
    _power:                             '_power',
    _start:                             '_start',
    _surveyid:                          '_surveyid',
    _tests:                             '_tests',
    _type:                              '_type',
    _wificells:                         '_wificells',

    _points:                            '_points',

    _testprofiledescription:            '_testprofiledescription',
    _testprofileid:                     '_testprofileid',

    _device:                            '_device',
    _deviceQuantity:                    '_deviceQuantity',
}; // _attrs


const _field = ElementType.Devicegroup;


// export class DeviceGroup extends ElementMainWithDeviceListCommon
export class DeviceGroup extends ElementMainCommon
{
    static get(d, attrs)
    {
        return d
            ? (d instanceof DeviceGroup
                ? d
                : new DeviceGroup(d, attrs)
            )
            
            : undefined;
    }


    static get field()
    {
        return _field;
    }


    static get attrs()
    {
        return _attrs;
    }


    //
    // Getters and setters
    //
    // [TBD] - needed for Web to build
    get description()
    {
        return this[ElementCommon.attrs._description];
    }


    get duration()
    {
        return (this.startI instanceof Dateq && this.endI instanceof Dateq)
            ? Math.round((this.endI - this.startI) / 1000) // convert to secs
            : undefined;
    }

    get deviceId()
    {
        return this[DeviceGroup.attrs._deviceid];
    }


    get deviceIds()
    {
        return Array.isArray(this.deviceList)
            ? this.deviceList.map(d => d.id)
            : [];
    }


    get deviceList()
    {
        return this[DeviceGroup.attrs._device];
    }
    

    get devices()
    {
        return (Number.isInteger(this[DeviceGroup.attrs._deviceQuantity]))
            ? this[DeviceGroup.attrs._deviceQuantity]
            : 0;
    }


    get end()
    {
        if (this.isFinished()) return this.endI.displayStr;
    }

    get endI()
    {
        return this[DeviceGroup.attrs._end];
    }

    set endI(d)
    {
        this[DeviceGroup.attrs._end] = this._getDt();
    }


    get geolocs()
    {
        return this[DeviceGroup.attrs._geolocs];
    }


    // [TBD] - needed for Web to build
    get id()
    {
        return this._id;
    }


    get instanceid()
    {
        return this[DeviceGroup.attrs._instanceid];
    }

    set instanceid(d)
    {
        this[DeviceGroup.attrs._instanceid] = d;
    }


    get kpis()
    {
        return this[DeviceGroup.attrs._kpis];
    }


    get mobilecells()
    {
        return this[DeviceGroup.attrs._mobilecells];
    }


    // [TBD] - needed for Web to build
    get name()
    {
        return this._name;
    }


    get online()
    {
        return this._online;
    }
    

    get pause()
    {
        return this[DeviceGroup.attrs._pause];
    }


    get points()
    {
        return this[DeviceGroup.attrs._points];
    }


    get pointsNum()
    {
        return Array.isArray(this.points) ? this.points.length : 0;
    }


    get power()
    {
        return this[DeviceGroup.attrs._power];
    }

    
    get stateI()
    {
        if      (this.isFinished()) return DeviceGroupStatus.Stopped;
        else if (this.isStarted())  return DeviceGroupStatus.Started;
        else                        return DeviceGroupStatus.Stopped;
    }


    // Override
    get state()
    {
        switch (this.stateI) {
            case DeviceGroupStatus.Started: return ElementState.Poor;
            case DeviceGroupStatus.Stopped: return ElementState.Good;

            case DeviceGroupStatus.None:
            // Fall through
            default:
                return ElementState.None;
        } // switch
    }


    get start()
    {
        // if (this.startI instanceof Dateq) console.log(this.startI.displayStr);
        if (this.startI instanceof Dateq) return this.startI.displayStr;
    }

    get startI()
    {
        return this[DeviceGroup.attrs._start];
    }

    set startI(d)
    {
        this[DeviceGroup.attrs._start] = this._getDt();
    }
    

    get surveyId()
    {
        return this[DeviceGroup.attrs._surveyid];
    }


    get testprofileDescription()
    {
        return this[DeviceGroup.attrs._testprofiledescription];
    }


    get testprofileId()
    {
        return this[DeviceGroup.attrs._testprofileid];
    }


    get tests()
    {
        return this[DeviceGroup.attrs._tests];
    }

    set tests(t)
    {
        this[DeviceGroup.attrs._tests] = t;
    }


    get type()
    {
        return this[DeviceGroup.attrs._type];
    }

    set type(t)
    {
        this[DeviceGroup.attrs._type] = t;
    }


    get wificells()
    {
        return this[DeviceGroup.attrs._wificells];
    }


    //
    // Public methods
    //
    isFinished()
    {
        return (this.endI instanceof Dateq);
    }


    isStarted()
    {
        return (this.startI instanceof Dateq);
    }


    update(d)
    {
        if (d instanceof DeviceCommon) {
            const obj = this.deviceList.find(x => x.id === d.id)
            if (obj) {
                obj._merge(d);
            }
            // else this.deviceList.push(d);
        }
        this._devicesUpdated(); // need to re-evaluate
    }


    // Override
    // setState()
    // {
    //     // Update state icon based on lowest of constituent devices

    //     let e = ElementState.None; // Only keep if no devices
    //     if (Array.isArray(this.deviceList)) {
    //         e = ElementState.Unknown;
    //         this.deviceList.forEach((d) => {
    //             if (d instanceof DeviceCommon) switch (d.state) {
    //                 case ElementState.Good:
    //                     if (ElementState.Average !== e && ElementState.Poor !== e) e = d.state;
    //                 break;

    //                 case ElementState.Average:
    //                     if (ElementState.Poor !== e) e = d.state;
    //                 break;

    //                 case ElementState.Poor:
    //                     e = d.state;
    //                 break;

    //                 case ElementState.None:
    //                     // Fall through
    //                 case ElementState.Unknown:
    //                     return d.state;
    //             } // switch
    //         }); // forEach
    //     }

    //     return (this.state = e);
    // }

    
    run()
    {
        this.startI = undefined; // will call setter
    }


    stop()
    {
        this.endI = undefined; // will call setter
    }


    //
    // Protected methods
    //
    _merge(d, attrsE, attrsI)
    {
        super._merge(d, attrsE, {..._attrs, ...attrsI});

        if (this[DeviceGroup.attrs._start]) this[DeviceGroup.attrs._start] = Dateq.get(this[DeviceGroup.attrs._start]);
        if (this[DeviceGroup.attrs._end])   this[DeviceGroup.attrs._end]   = Dateq.get(this[DeviceGroup.attrs._end]);

        this[DeviceGroup.attrs._device] 
            = ElementHelperCommon.convertArray(this, DeviceGroup.attrs._device, DeviceHelper.field);
        this._devicesUpdated(); // need to re-evaluate
        // this[DeviceGroup.attrs._online] = this[DeviceGroup.attrs._device].some((d) => d.status =="online");

        // if ( this[ElementMainCommon.attrs._state]) this.setState(); // need to re-calc after updating devices
    }


    _devicesUpdated()
    {
        this[DeviceGroup.attrs._online] = this[DeviceGroup.attrs._device].some((d) => d.status =="online");

        if ( this[ElementMainCommon.attrs._state]) this.setState(); // need to re-calc after updating devices
    }


    _getDt()
    {
        return Dateq.now();
    }
}