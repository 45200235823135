'use strict'


import { Dateq }                  from '../../index.mjs';

import { AttributesHelperCommon } from '../Devices/Attributes/index.mjs';
import { MobileCellHelperCommon } from '../MobileCell/index.mjs';
import { ElementCommon }          from '../Element.class.mjs';
import { ElementHelperCommon }    from '../Element-helper.class.mjs';
import { ElementState }           from '../ElementState.class.mjs';
import { ElementType }            from '../ElementType.class.mjs';
import { Image }                  from '../Image/index.mjs';

import { SurveyPoint }            from './SurveyPoint.class.mjs';
import { SurveyStatus }           from './SurveyStatus.class.mjs';


const _attrs = {
    [ElementCommon.attrs._description]: ElementCommon.attrs._description,
    _deviceId:                          '_deviceId',
    _end:                               '_end',
    _geolocs:                           '_geolocs',
    [ElementCommon.attrs._id]:          ElementCommon.attrs._id,
    _images:                            '_images',
    _kpis:                              '_kpis',
    [ElementCommon.attrs._name]:        ElementCommon.attrs._name,
    _mobilecells:                       '_mobilecells',
    _pause:                             '_pause',
    _pointid:                           '_pointid',
    _pointname:                         '_pointname',
    _power:                             '_power',
    _start:                             '_start',
    _surveyId:                          '_surveyId',
    _tests:                             '_tests',
    _type:                              '_type',
    _wificells:                         '_wificells',

    _points:                            '_points'
}; // _attrs


const _field = ElementType.Survey;


export class Survey extends ElementCommon
{
    static get(d, attrs)
    {
        return d
            ? (d instanceof Survey
                ? d
                : new Survey(d, attrs)
            )
            
            : undefined;
    }


    static get field()
    {
        return _field;
    }


    static get attrs()
    {
        return _attrs;
    }


    //
    // Getters and setters
    //
    // [TBD] - needed for Web to build
    get description()
    {
        return this[ElementCommon.attrs._description];
    }


    get duration()
    {
        return this.startI instanceof Dateq && this.endI instanceof Dateq
            ? Math.round((this.endI - this.startI) / 1000) // convert to secs
            : undefined;
    }

    get deviceId()
    {
        return this[Survey.attrs._deviceId];
    }


    get endI()
    {
        return this[Survey.attrs._end];
    }

    get end()
    {
        if (this.isFinished()) return this.endI.displayStr;
    }


    get geolocs()
    {
        return this[Survey.attrs._geolocs];
    }


    // [TBD] - needed for Web to build
    get id()
    {
        return this._id;
    }


    get images()
    {
        return this._images;
    }


    get kpis()
    {
        return this[Survey.attrs._kpis];
    }


    get mobilecells()
    {
        return this[Survey.attrs._mobilecells];
    }


    // [TBD] - needed for Web to build
    get name()
    {
        return this._name;
    }
    

    get pause()
    {
        return this[Survey.attrs._pause];
    }


    get points()
    {
        return this[Survey.attrs._points];
    }


    get pointsNum()
    {
        return Array.isArray(this.points) ? this.points.length : 0;
    }


    get power()
    {
        return this[Survey.attrs._power];
    }

    
    get stateI()
    {
        if      (this.isFinished()) return SurveyStatus.Stopped;
        else if (this.isStarted())  return SurveyStatus.Started;
        else                        return SurveyStatus.Unknown;
    }


    // Override
    get state()
    {
        switch (this.stateI) {
            case SurveyStatus.Started: return ElementState.Poor;
            case SurveyStatus.Stopped: return ElementState.Good;

            case SurveyStatus.None:
            // Fall through
            default:
                return ElementState.None;
        } // switch
    }


    get startI()
    {
        return this[Survey.attrs._start];
    }
    
    get start()
    {
        if (this.isStarted()) return this.startI.displayStr;
    }


    get surveyId()
    {
        return this[Survey.attrs._surveyId];
    }


    get tests()
    {
        return this[Survey.attrs._tests];
    }

    set tests(t)
    {
        this[Survey.attrs._tests] = t;
    }


    get type()
    {
        return this[Survey.attrs._type];
    }

    set type(t)
    {
        this[Survey.attrs._type] = t;
    }


    get wificells()
    {
        return this[Survey.attrs._wificells];
    }


    //
    // Public methods
    //
    isFinished()
    {
        return (this.endI instanceof Dateq);
    }


    isStarted()
    {
        return (this.startI instanceof Dateq);
    }


    static process2(d, t, fn)
    {
        const o = d
            ? Survey._convertArray2(ElementCommon._getAttr(d, t), fn)
            : undefined;

        return Array.isArray(o) ? o : [];
    }


    //
    // Protected methods
    //
    static _convertArray(d, a)
    {
        const os = [];

        if (d && a && Array.isArray(d[a])) {
            d[a].forEach((o) => {
                const o2 = ElementHelperCommon.get(o, a);
                if (o2) os.push(o2);
            }); // forEach
        }

        return os;
    }


    _merge(d, attrsE, attrsI)
    {
        // this._setAttrs2(d, attrsE ? attrsE : {..._attrs, ...attrsI}); // [TBD]
        super._merge(d, attrsE, {..._attrs, ...attrsI});

        // console.log("Stu survey date");
        // console.log(JSON.stringify(d));
        // console.log(JSON.stringify(this[Survey.attrs._start]))
        // console.log(JSON.stringify(this[Survey.attrs._end]))
        if (this[Survey.attrs._start]) this[Survey.attrs._start] = Dateq.get(this[Survey.attrs._start]);
        if (this[Survey.attrs._end])   this[Survey.attrs._end]   = Dateq.get(this[Survey.attrs._end]);

        // console.log(JSON.stringify(this[Survey.attrs._start]))
        // console.log(JSON.stringify(this[Survey.attrs._end]))

        // Below will all create empty [] if d is not valid or no such elements exist in d
        this[Survey.attrs._geolocs]     = Survey._process(d, Survey.attrs._geolocs,     ElementType.Geolocation);
        this[Survey.attrs._kpis]        = Survey._process(d, Survey.attrs._kpis,        ElementType.Kpi);
        this[Survey.attrs._mobilecells] = Survey._process(d, Survey.attrs._mobilecells, ElementType.Mobilecell);
        this[Survey.attrs._power]       = Survey._process(d, Survey.attrs._power,       ElementType.Power);
        this[Survey.attrs._tests]       = Survey._process(d, Survey.attrs._tests,       ElementType.Test);
        this[Survey.attrs._wificells]   = Survey._process(d, Survey.attrs._wificells,   ElementType.Wificell);

        this[Survey.attrs._points]      = Survey.process2(d, Survey.attrs._points,      SurveyPoint.get);

        this[Survey.attrs._images]      = Survey.process2(d, Survey.attrs._images,      Image.get);

        return this;
    }


    //
    // Private methods
    //
    static _process(d, f, t)
    {
        const o = d
            ? AttributesHelperCommon.convertArray(d, f, t)
            : undefined;

        return Array.isArray(o) ? o : [];
    }


    static _convertArray2(a, fn)
    {
        let os = undefined;

        if (fn instanceof Function) {
            if (Array.isArray(a)) {
                os = [];
                a.forEach((o) => {
                    const o2 = fn(o);
                    if (o2) os.push(o2);
                }); // forEach
            }
            else {
                os = fn(a);
            }
        }

        return os;
    }
}