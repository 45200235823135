'use strict'


// import { DeviceMsgsModule } from '../../messages/index.mjs';

import { ElementType }  from '../ElementType.class.mjs';
import { Notification } from './Notification.class.mjs';


// const _field     = DeviceMsgsModule.attributes.notification;
const _field     = ElementType.Notification;//DeviceMsgsModule.attributes.notification;
const _typeField = Notification.typeField;


export class NotificationHelper
{
    // Factory method
    static get(d, attrs)
    {
        if (d instanceof Notification) return d;
 
        let fn = undefined;
        if (d) {
            fn = Notification.get;
            
            // switch (this._getType(d)) {
            // } // switch
        }

        return fn instanceof Function
            ? fn(d, attrs) 
            : undefined;
    }


    static get field()
    {
        return _field;
    }
    

    //
    // Private methods
    //
    static _getType(d)
    {
        return d && _typeField && _typeField in Object(d) ? Object(d)[_typeField] : undefined;
    }
}