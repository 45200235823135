'use strict'


const _types = {
    connected:    'connected',
    disconnected: 'disconnecteed',
    reconnected:  'reconnected',
    unknown:      'unknown'
}; // _types


export class ConnectionState
{
    static Connected    = new ConnectionState(_types.connected);
    static Disconnected = new ConnectionState(_types.disconnected);
    static Reconnected  = new ConnectionState(_types.reconnected);
    static Unknown      = new ConnectionState(_types.unknown);
  

    constructor(d)
    {
       this._type = d;
    }


    // Factory
    static get(d)
    {
        switch(d) {
            case _types.connected:    return ConnectionState.Connected;
            case _types.disconnected: return ConnectionState.Disconnected;
            case _types.reconnected:  return ConnectionState.Reconnected;

            case _types.unknown:
            default:
                return ConnectionState.Unknown;
        } // switch
    }


    get state()
    {
        return this._type;
    }


    // Override
    toString()
    {
        return "ConnectionState: " + this.state;
    }
}