'use strict'


import { Dateq }            from '../../index.mjs';
import { ElementCommon }     from '../Element.class.mjs';
import { ElementMainCommon } from '../ElementMain.class.mjs';
import { ElementType }       from '../ElementType.class.mjs';

import { Image }             from '../Image/index.mjs';

import { Survey }            from './Survey.class.mjs';
import { SurveySighting }    from './SurveySighting.class.mjs';


const _attrs = {
    _end:                        '_end',
    _geolocs:                    '_geolocs',
    [ElementCommon.attrs._id]:   ElementCommon.attrs._id,
    [ElementCommon.attrs._name]: ElementCommon.attrs._name,
    _images:                     '_images',
    _power:                      '_power',
    _sightings:                  '_sightings',
    _start:                      '_start'
}; // _attrs


const _field = ElementType.Survey;


export class SurveyPoint extends ElementMainCommon
{
    static get(d, attrs)
    {
        return d
            ? (d instanceof SurveyPoint
                ? d
                : new SurveyPoint(d, attrs)
            )
            
            : undefined;
    }


    static get field()
    {
        return _field;
    }


    static get attrs()
    {
        return _attrs;
    }


    //
    // Getters and setters
    //
    get endI()
    {
        return this[SurveyPoint.attrs._end];
    }

    get end()
    {
        if (this.endI instanceof Dateq) return this.endI.displayStr;
    }


    get geolocs()
    {
        return this[SurveyPoint.attrs._geolocs];
    }

    // Override
    get geolocation()
    {
        return Array.isArray(this.geolocs) ? this.geolocs.find(d => !!d) : undefined; // finds first 'truthy' element
    }


    // [TBD] - needed for Web to build
    get id()
    {
        return this._id;
    }


    get images()
    {
        return this._images;
    }


    // [TBD] - needed for Web to build
    get name()
    {
        return this._name;
    }
    

    get power()
    {
        return this[SurveyPoint.attrs._power];
    }


    get sightings()
    {
        return this[SurveyPoint.attrs._sightings];
    }


    get startI()
    {
        return this[SurveyPoint.attrs._start];
    }
    
    get start()
    {
        if (this.startI instanceof Dateq) return this.startI.displayStr;
    }


    //
    // Protected methods
    //
    _merge(d, attrsE, attrsI)
    {
        // this._setAttrs2(d, attrsE ? attrsE : {..._attrs, ...attrsI}); // [TBD]
        super._merge(d, attrsE, {..._attrs, ...attrsI});

        // console.log("Stu survey date");
        // console.log(JSON.stringify(d));
        // console.log(JSON.stringify(this[SurveyPoint.attrs._start]))
        // console.log(JSON.stringify(this[SurveyPoint.attrs._end]))
        if (this[SurveyPoint.attrs._start]) this[SurveyPoint.attrs._start] = Dateq.get(this[SurveyPoint.attrs._start]);
        if (this[SurveyPoint.attrs._end])   this[SurveyPoint.attrs._end]   = Dateq.get(this[SurveyPoint.attrs._end]);

        if (this[ElementCommon.attrs._id] < 0) this[ElementCommon.attrs._id] = 0; // don't allow values of < 0

        // Below will all create empty [] if d is not valid or no such elements exist in d
        this[SurveyPoint.attrs._geolocs]   = Survey._process(d, SurveyPoint.attrs._geolocs,     ElementType.Geolocation);
        // this[SurveyPoint.attrs._kpis]        = SurveyPoint._process(d, SurveyPoint.attrs._kpis,        ElementType.Kpi);
        // this[SurveyPoint.attrs._mobilecells] = SurveyPoint._process(d, SurveyPoint.attrs._mobilecells, ElementType.Mobilecell);
        this[SurveyPoint.attrs._power]     = Survey._process(d, SurveyPoint.attrs._power,       ElementType.Power);
        // this[SurveyPoint.attrs._tests]       = SurveyPoint._process(d, SurveyPoint.attrs._tests,       ElementType.Test);
        // this[SurveyPoint.attrs._wificells]   = SurveyPoint._process(d, SurveyPoint.attrs._wificells,   ElementType.Wificell);

        this[SurveyPoint.attrs._sightings] = Survey.process2(d, SurveyPoint.attrs._sightings,     SurveySighting.get);
        this[SurveyPoint.attrs._images]    = Survey.process2(d, SurveyPoint.attrs._images,        Image.get);

        // if (! this[SurveyPoint.attrs._images]) this[SurveyPoint.attrs._images] = []; // [TBD]Survey._process(d, SurveyPoint.attrs._geolocs,     ElementType.Geolocation);

        return this;
    }
}