'use strict';


import { ElementCommon } from '../Element.class.mjs';
import { ElementType }   from '../ElementType.class.mjs';


const _attrs = {
    _data:                       '_data',
    [ElementCommon.attrs._id]:   ElementCommon.attrs._id,
    [ElementCommon.attrs._name]: ElementCommon.attrs._name,
    _path:                       '_path'
}; // _attrs

const _field = ElementType.Image;


export class Image extends ElementCommon
{
    static get(d, attrs)
    {
        return d
            ? (d instanceof Image
                ? d
                : new Image(d, attrs)
            )
            : undefined;
    }


    static get attrs()
    {
        return _attrs;
    }


    static get field()
    {
        return _field;
    }


    //
    // Getters and setters
    //
    get name()
    {
        return this[Image.attrs._name];
    }


    get path()
    {
        return this[Image.attrs._path];
    }

    set path(d)
    {
        this[Image.attrs._path] = d;
    }


    //
    // Public methods
    //

    // Override
    isValid()
    {
        return super.isValid()
            && ! ElementCommon._isEmptyIgnoringAttrbute(this, ElementCommon.attrs._id)
            && this.path !== null;
    }


    //
    // Protected methods
    //
    
    // Override
    _merge(d, attrs)
    {
        super._merge(d, attrs, attrs ? undefined : Image.attrs);
    }
}