'use strict'


import { ElementStatus } from '../ElementStatus.class.mjs';


// const _types = {
//     paused:  'paused',
//     started: 'started',
//     stopped: 'stopped',
//     unknown: 'unknown'
// }; // _types


export class SurveyStatus extends ElementStatus
{
    static Paused  = new SurveyStatus(ElementStatus.types.paused);
    static Started = new SurveyStatus(ElementStatus.types.started);
    static Stopped = new SurveyStatus(ElementStatus.types.stopped);
    static Unknown = new SurveyStatus(ElementStatus.types.unknown);


    constructor(d)
    {
        super(d);
    }


    // static Paused  = new SurveyStatus(_types.paused);
    // static Started = new SurveyStatus(_types.started);
    // static Stopped = new SurveyStatus(_types.stopped);
    // static Unknown = new SurveyStatus(_types.unknown);


    // constructor(d)
    // {
    //     this._type = d;
    // }


    // // Factory
    // static get(d)
    // {
    //     switch (d) {
    //         case SurveyStatus.types.paused:  return SurveyStatus.Paused;
    //         case SurveyStatus.types.started: return SurveyStatus.Started;
    //         case SurveyStatus.types.stopped: return SurveyStatus.Stopped;

    //         case SurveyStatus.types.unknown:
    //         default:
    //             return SurveyStatus.Unknown;
    //     } // switch
    // }


    // static get types()
    // {
    //     return _types;
    // }


    // get status()
    // {
    //     return this._type;
    // }


    // Override
    toString()
    {
        return "SurveyStatus: " + this.status;
    }
}