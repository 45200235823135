'use strict';


import {
    BehaviorSubject,
    Subject
}                     from 'rxjs';


const trace = console;


const SocketIoEventsSock = {
    connect:           'connect',
    connect_error:     'connect_error',
    connect_timeout:   'connect_timeout',
    disconnect:        'disconnect'
    // disconnecting:     'disconnecting' // deprecated
};


const SocketIoEventsMgr = {
    close:             'close',
    error:             'error',
    open:              'open',
    ping:              'ping',
    reconnect:         'reconnect',
    // reconnecting:      'reconnecting',  // deprecated
    reconnect_attempt: 'reconnect_attempt',
    reconnect_error:   'reconnect_error',
    reconnect_failed:  'reconnect_failed'
};


const SocketIoEvents = {
    ...SocketIoEventsSock,
    ...SocketIoEventsMgr,
    data:              'data',
    packet:            'packet',
    pong:              'pong',
};


export class SocketIoEvent
{
    event;
    data;


    constructor(ev, d)
    {
        this.event = ev;
        this.data  = d;
    }


    static get events()
    {
        return SocketIoEvents;
    }
}


export class SocketIoBase
{
    // [TBD] Move to passed in config
    #buffer          = "";
    #host            = ""//10.4.31.51"; // '#' means private (from ES2019)
    #port            = 0;//12346;//12345;
    #reconnect       = false;
    #socket          = undefined;
    
    #fns             = undefined;
    #ioSocket        = undefined;

    #connectAttempts = 0;
    #reconnectTimer  = undefined;
    #socketEvent$    = undefined;
    #socketMsg$      = undefined;


    constructor(ioSocket, socket, noInit)
    {
        this.#fns = {
            [SocketIoEvents.close]:             this._socketClose,
            [SocketIoEvents.connect]:           this._socketConnect,
            [SocketIoEvents.connect_error]:     this._socketConnectError,
            [SocketIoEvents.connect_timeout]:   this._socketConnectTimeout,
            [SocketIoEvents.data]:              undefined,
            [SocketIoEvents.disconnect]:        this._socketDisconnect,
            // [SocketIoEvents.disconnecting]:     this._socketDisconnecting,
            [SocketIoEvents.error]:             this._socketError,
            [SocketIoEvents.open]:              this._socketOpen,
            [SocketIoEvents.packet]:            this._socketPacket,
            [SocketIoEvents.ping]:              this._socketPing,
            [SocketIoEvents.pong]:              this._socketPong,
            [SocketIoEvents.reconnect]:         this._socketReconnect,
            // [SocketIoEvents.reconnecting]:      this._socketReconnecting,
            [SocketIoEvents.reconnect_attempt]: this._socketReconnectAttempt,
            [SocketIoEvents.reconnect_error]:   this._socketReconnectError,
            [SocketIoEvents.reconnect_failed]:  this._socketReconnectFailed
        }; // fns

        this.#ioSocket     = ioSocket;
        this.#socket       = socket;
        this.#socketEvent$ = new BehaviorSubject(undefined);
        this.#socketMsg$   = new BehaviorSubject(undefined);

        if (! noInit) this._initialise();
    }


    //
    // Getters and setters
    //
    get host()
    {
        return this.#host;
    }


    get id()
    {
        return this.socket ? this.socket.id : undefined;
    }
    

    get ioSocket()
    {
        return this.#ioSocket;
    }


    get localAddress()
    {
        return this.host + ":" + this.port;
    }


    get port()
    {
        return this.#port;
    }


    get _reconnectAttempts()
    {
        return (this.socket instanceof this.ioSocket
                && Object(this.socket.io)
                && Object(this.socket.io).reconnectionAttempts instanceof Function)
            ? this.socket.io.reconnectionAttempts()
            : 0;
    }


    set reconnection(d)
    {
        if (this.socketIo) this.socketIo.reconnection = d;
    }


    set reconnectionDelay(d)
    {
        if (this.socketIo) this.socketIo.reconnectionDelay = d;
    }


    set reconnectionDelayMax(d)
    {
        if (this.socketIo) this.socketIo.reconnectionDelayMax = d;
    }


    get _reconnectTimer()
    {
        return this.#reconnectTimer;
    }

    set _reconnectTimer(timer)
    {
        if (this.#reconnectTimer) clearTimeout(this.#reconnectTimer);
        this.#reconnectTimer = timer;
    }


    get remoteAddress()
    {
        return (this.socket instanceof this.ioSocket
                && this.socket.io
                && 'uri' in Object(this.socket.io))
            ? Object(this.socket.io)['uri'] // do like this as .uri is private in SocketIO v4.x
            : "<unknown>";
    }


    get socket()
    {
        return this.#socket;
    }


    get socketIo()
    {
        return (this.socket instanceof this.#ioSocket) ? this.socket.io : undefined;
    }


    get socketEvent$()
    {
        return (this.#socketEvent$ instanceof Subject) ? this.#socketEvent$.asObservable() : undefined;
    }


    get socketMsg$()
    {
        return (this.#socketMsg$ instanceof Subject) ? this.#socketMsg$.asObservable() : undefined;
    }


    //
    // Static methods
    //
    static get(ioSocket, socket, noInit)
    {
        return (ioSocket && socket) ? new SocketIoBase(ioSocket, socket, noInit) : undefined;
    }


    //
    // Public methods
    //
    cleanUp(disconnect)
    {
        if (this.socket instanceof this.#ioSocket) {
            if (this.socket.removeAllListeners instanceof Function) this.socket.removeAllListeners();

            if (disconnect) {
                if (this.socket.offAny instanceof Function) this.socket.offAny();

                if (this.socket.off instanceof Function) {
                    Object.values(SocketIoEventsSock).forEach((d) => {
                        this.socket.off(d);
                    }); // forEach
                }
    
                if (this.socket.io && this.socket.io.off instanceof Function) {
                    Object.values(SocketIoEventsMgr).forEach((d) => {
                        this.socket.io.off(d);
                    }); // forEach
                }

                if (this.socket.disconnect instanceof Function) this.socket.disconnect(true);
            }
        }
    }


    emit(msg, d, raw)
    {
        try {
            if (this.socket instanceof this.#ioSocket) {
// trace.debug(msg);
// trace.debug(d);
if (raw) trace.debug(raw);
                if (raw) this.socket.emit(raw, msg, d);
                else     this.socket.emit(msg, d);
                // this.socket.emit((raw ? raw : _wta.message), msg, d);

                return true;
            }
            else {
                trace.warn("Unable to send msg");
                trace.debug(JSON.stringify(this.socket));
            }
        }
        catch (e) {
            trace.error("Failed to send msg");
            trace.debug(e);
            trace.debug(JSON.stringify(e));
            trace.debug(JSON.stringify(d));
        }
    }


    join(r)
    {
        trace.debug("Socket joined room: " + this.id + ", " + r);

        return (this.socket instanceof this.#ioSocket && this.socket.join instanceof Function)
            ? this.socket.join(r)
            : undefined;
    }


    leave(r)
    {
        trace.debug("Socket left room: " + this.id + ", " + r);
        
        return (this.socket instanceof this.#ioSocket && this.socket.leave instanceof Function)
            ? this.socket.leave(r)
            : undefined;
    }


    // reconnect()
    // {
    //     trace.log("Stu reconnect");
    //     trace.log(this.socket)
    //     trace.log(this.socket.socket)
    //     return this.socket instanceof this.#ioSocket && this.socket.reconnect instanceof Function
    //         ? this.socket.reconnect()
    //         : undefined;
    // }


    rooms()
    {
        return (this.socket instanceof this.#ioSocket)
            ? this.socket.rooms
            : undefined;
    }


    //
    // Protected methods
    //
    _initialise()
    {
        this.#buffer = "";

        if (this.socket instanceof this.ioSocket) {
            trace.debug("Connecting to IO socket");// + this.localAddress + ", attempt: " + ++this.#connectAttempts);

            // socketIo v3+
            if (this.socket.onAny instanceof Function) {
                this.socket.onAny((ev, ...args) => this._process(ev, ...args));
            }

            // onAny doesn't seem work with below events
            if (this.socket.on instanceof Function) {
                Object.values(SocketIoEventsSock).forEach((d) => {
                    this.socket.on(d, (...args) => this._process(d, ...args));
                }); // forEach
            } // this.socket.on

            if (this.socket.io) {
                // No onAny on .io (Manager)

                if (this.socket.io.on instanceof Function) {
                    Object.values(SocketIoEventsMgr).forEach((d) => {
                        this.socket.io.on(d, (...args) => this._process(d, ...args));
                    }); // forEach
                } // this.socket.on
            } // this.socket.io
        }
        else {
            trace.debug("Unable to connect socket: ");// + this.client + ", " + this.localAddress + ", attempt: " + this.#connectAttempts);
            //return this._reconnect();
        }
    }


    //
    // Private methods
    //
    _process(ev, ...args)
    {
        if (ev && ev in Object(this.#fns)) {
            const fn = Object(this.#fns)[ev];
            if (fn instanceof Function) {
                fn.apply(this, args);
                return this._sendEvent(this.#socketEvent$, ev, ...args);
            }

            // Don't do anything if event exists but fn is undefined
        }
        else {
            return this._sendEvent(this.#socketMsg$, ev, ...args);
        }
    }


    _socketConnect()
    {
        trace.debug("Socket connected: " + this.localAddress + ", " + this.remoteAddress + ", attempt: " + this.#connectAttempts);
        trace.debug(this.socket.io)
    }


    _socketConnectError(error)
    {
        trace.warn("Socket connect error: " + this.localAddress + ", " + this.remoteAddress + ", " + error);
        this.cleanUp();
    }


    _socketConnectTimeout(timeout)
    {
        trace.debug("Socket connect timeout: " + this.localAddress + ", " + this.remoteAddress + ", " + timeout);
    }


    _socketClose()
    {
        trace.debug("Socket close: " + this.localAddress + ", " + this.remoteAddress);
        this.cleanUp();
    }


    _socketDisconnect(reason)
    {
        trace.debug("Socket disconnected: " + this.localAddress + ", " + this.remoteAddress + ", " + reason);
        this.cleanUp();
    }


    _socketDisconnecting(reason)
    {
        trace.debug("Socket disconnecting: " + this.localAddress + ", " + this.remoteAddress + ", " + reason);
        trace.debug("Rooms: " + Object.keys(Object(this.socket.rooms)) );
    }

  
    _socketError(error)
    {
        trace.warn("Socket error: " + this.localAddress + ", " + this.remoteAddress + ", " + error);
        this.cleanUp();
    }


    _socketOpen()
    {
        trace.debug("Socket open: " + this.localAddress + ", " + this.remoteAddress);
    }


    _socketPacket()
    {
        trace.debug("Socket packet: " + this.localAddress + ", " + this.remoteAddress);
    }


    _socketPing()
    {
        // trace.debug("Socket ping");
    }


    _socketPong()
    {
        trace.debug("Socket pong");
    }


    _socketReconnect(attempt)
    {
        trace.debug("Socket reconnected: " + attempt + "/ " + this._reconnectAttempts);
    }

    
    _socketReconnecting(attempt)
    {
        trace.debug("Socket reconnecting: " + attempt + "/ " + this._reconnectAttempts);
    }


    _socketReconnectAttempt(attempt)
    {
        trace.debug("Socket reconnect attempt: " + attempt + "/ " + this._reconnectAttempts);
    }


    _socketReconnectError(error)
    {
        trace.debug("Socket reconnect error");
        trace.debug(error);
    }


    _socketReconnectFailed()
    {
        trace.debug("Socket reconnect failed: "  + this._reconnectAttempts);
        this.cleanUp();
    }


    _sendEvent(sub, ev, d)
    {
        return sub instanceof Subject
            ? sub.next(new SocketIoEvent(ev, d))
            : undefined;
    }
}