'use strict'


import { MobileCellCommon } from './MobileCell.class.mjs';


const _attrs = {
    _cqi:     '_cqi',
    _ecid:    '_ecid',
    _lcid:    '_lcid',
    _pci:     '_pci',
    _rsrpcsi: '_rsrpcsi',
    _rsrpss:  '_rsrpss',
    _rsrqcsi: '_rsrqcsi',
    _rsrqss:  '_rsrqss',
    _sinrcsi: '_sinrcsi',
    _sinrss:  '_sinrss'
}


export class MobileCellNrCommon extends MobileCellCommon
{
    static get(d, attrs)
    {
        return new MobileCellNrCommon(d, attrs);
    }


    static get attrs()
    {
        return _attrs;
    }



    //
    // Getters and setters
    //

    // Override
    get cellcode()
    {
        return this.pci;
    }

    
    // Override
    get signalquality()
    {
        return this.rsrqss;
    }

    // Override
    get signalqualitycategory()
    {
        // Keep in sync with Android \java\com\spryfoxnetworks\quantumpath\cellular\QPMobileCellLte.java#getSigQualityCategory

        if (this.signalquality !== null) {
            if      (this.signalquality == 0)   return MobileCellCommon.signalCategories.Invalid

            else if (this.signalquality >= -12) return MobileCellCommon.signalCategories.Great;
            else if (this.signalquality >= -14) return MobileCellCommon.signalCategories.Good;
            else if (this.signalquality >= -17) return MobileCellCommon.signalCategories.Average;
            else if (this.signalquality >= -19) return MobileCellCommon.signalCategories.Poor;
        }
        
        return MobileCellCommon.signalCategories.Bad;
    }
    

    // Override
    get signalstrength()
    {
        return this.rsrpss;
    }

    // Override
    get signalstrengthcategory()
    {
        // Keep in sync with Android \java\com\spryfoxnetworks\quantumpath\cellular\QPMobileCellLte.java#getSigStrengthCategory

        if (this.signalstrength !== null) {
            if      (this.signalstrength == 0)    return MobileCellCommon.signalCategories.Invalid

            else if (this.signalstrength >= -85)  return MobileCellCommon.signalCategories.Great;
            else if (this.signalstrength >= -95)  return MobileCellCommon.signalCategories.Good;
            else if (this.signalstrength >= -105) return MobileCellCommon.signalCategories.Average;
            else if (this.signalstrength >= -115) return MobileCellCommon.signalCategories.Poor;
        }

        return MobileCellCommon.signalCategories.Bad;
    }


    get cqi()
    {
        return this[MobileCellNrCommon.attrs._cqi];
    }


    get ecid()
    {
        return this[MobileCellNrCommon.attrs._ecid];
    }


    get lcid()
    {
        return this[MobileCellNrCommon.attrs._lcid];
    }


    get pci()
    {
        return this[MobileCellNrCommon.attrs._pci];
    }


    get rsrpcsi()
    {
        return this[MobileCellNrCommon.attrs._rsrpcsi];
    }

    get rsrpss()
    {
        return this[MobileCellNrCommon.attrs._rsrpss];
    }


    get rsrqcsi()
    {
        return this[MobileCellNrCommon.attrs._rsrqcsi];
    }

    get rsrqss()
    {
        return this[MobileCellNrCommon.attrs._rsrqss];
    }


    get sinrcsi()
    {
        return this[MobileCellNrCommon.attrs._sinrcsi];
    }

    get sinrss()
    {
        return this[MobileCellNrCommon.attrs._sinrss];
    }


    get tac()
    {
        return this.areacode;
    }


    //
    // Protected methods
    //
    _merge(d, attrs)
    {
        super._merge(d, attrs, attrs ? undefined : MobileCellNrCommon.attrs);

        // If both cellId and ecid are populated, then this is a Nextivity repeater
        // cell and cellId is really lcid.  Swap and calcuate cellId
        // if (this.cellId) {
        //     if (this._ecid && ! this._lcid) {
        //         this._cellId = MobileCellLteCommon.idsToGcid(this._ecid, this._lcid = this.cellId);
        //     }
        //     else {
        //         this._ecid  = MobileCellLteCommon.gcidToLcid(this.cellId);
        //         this._lcid  = MobileCellLteCommon.gcidToEcid(this.cellId);
        //     }
        // }

        return this;
    }
}