'use strict'


// import { DeviceMsgsModule }  from '../../messages/index.mjs';

import { ElementType }       from '../ElementType.class.mjs';

import { MobileCellCommon }  from './MobileCell.class.mjs'; // has to be like this otherwise runtime error
import {
    MobileCellCdmaCommon,
    MobileCellGsmCommon,
    MobileCellLteCommon,
    MobileCellNrCommon,
    MobileCellNrnsaCommon,
    MobileCellTdscdmaCommon,
    MobileCellUmtsCommon,
    MobileCellType
    // MobileCellTypes // Deprecated
}                            from './index.mjs';


// const _field     = DeviceMsgsModule.attributes.mobilecell;
const _field = ElementType.Mobilecell;//_da.mobilecell;
const _typeField = MobileCellCommon.typeField;


export class MobileCellHelperCommon
{
    // Factory method
    static get(d, attrs)
    {
        if (d instanceof MobileCellCommon) return d;

        let fn = undefined;
        if (d) {
            switch (MobileCellType.get(MobileCellHelperCommon._getType(d)) ) {
                case MobileCellType.Cdma:
                    fn = MobileCellCdmaCommon.get;
                break;

                case MobileCellType.Gsm:
                    fn = MobileCellGsmCommon.get;
                break;

                case MobileCellType.Lte:
                    fn = MobileCellLteCommon.get;
                break;

                case MobileCellType.Nr:
                    fn = MobileCellNrCommon.get;
                break;

                case MobileCellType.Nr_nsa:
                    fn = MobileCellNrnsaCommon.get;
                break;

                case MobileCellType.Td_scdma:
                    fn = MobileCellTdscdmaCommon.get;
                break;

                case MobileCellType.Umts:
                    fn = MobileCellUmtsCommon.get;
            //     break;
            // switch (MobileCellHelperCommon._getType(d)) {
            //     case MobileCellTypes.cdma:
            //         fn = MobileCellCdmaCommon.get;
            //     break;

            //     case MobileCellTypes.gsm:
            //         fn = MobileCellGsmCommon.get;
            //     break;

            //     case MobileCellTypes.lte:
            //         fn = MobileCellLteCommon.get;
            //     break;

            //     case MobileCellTypes.nr:
            //         fn = MobileCellNrCommon.get;
            //     break;

            //     case MobileCellTypes.td_scdma:
            //         fn = MobileCellTdscdmaCommon.get;
            //     break;

            //     case MobileCellTypes.umts:
            //         fn = MobileCellUmtsCommon.get;
            //     break;
            } // switch
        }

        return (fn instanceof Function)
            ? fn(d, attrs) 
            : undefined;
    }


    static get field()
    {
        return _field;
    }


    //
    // Private methods
    //
    static _getType(d)
    {
        return (d && _typeField && _typeField in Object(d))
            ? Object(d)[_typeField]
            : undefined;
    }
}