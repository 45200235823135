'use strict';


import { ElementCommon }    from '../Element.class.mjs';


const _attrs = {
    [ElementCommon.attrs._id]: ElementCommon.attrs._id,
    _type:                     '_type'
};


const _typeField = _attrs._type;


export const KpiTypes = {
    mobileTechnologyOverTime: 'mobileTechnologyOverTime',

    // TBD - legacy
    dataDnsLatencyAvg:             'dataDnsLatencyAvg',
    dataHttpDlSuccessRate:         'dataHttpDlSuccessRate',
    dataHttpDlThroughputAvg:       'dataHttpDlThroughputAvg',
    dataIcmpDlDroppedPktRate:      'dataIcmpDlDroppedPktRate',
    dataIcmpDlLatencyAvg:          'dataIcmpDlLatencyAvg',
    ipAddressOverTime:             'ipAddressOverTime',
    ipNetworkConnectivityOverTime: 'ipNetworkConnectivityOverTime',
    // mobileTechnologyOverTime:      'mobileTechnologyOverTime',
    voiceCallDropRate:             'voiceCallDropRate',
    voiceCallSuccessRate:          'voiceCallSuccessRate'
};
export const _kpiTypes = KpiTypes;


export class Kpi extends ElementCommon
{
    static get attrs()
    {
        return _attrs;
    }


    static get typeField()
    {
        return _typeField;
    }
    

    static get types()
    {
        return _kpiTypes;
    }


    //
    // Getters and setters
    //


    //
    // Protected methods
    //
    _merge(d, attrsE, attrsI)
    {
        return super._merge(d, attrsE, {...Kpi.attrs, ...attrsI});
    }
}