'use strict'


import { MobileCellCommon } from './MobileCell.class.mjs';


const _attrs = {
    _cqi:   '_cqi',
    _ecid:  '_ecid',
    _lcid:  '_lcid',
    _pci:   '_pci',
    _rsrp:  '_rsrp',
    _rsrq:  '_rsrq',
    _rssnr: '_rssnr'
}

const _cidMask = 0xff;


export class MobileCellLteCommon extends MobileCellCommon
{
    // constructor(d, attrs)
    // {
    //     super(d, attrs);

        //{"cellinstance":897,"serialnumber":"933904000008","technology":"lte",
        //"lasttechdata":"2019-03-10t14:02:53z","dlfrequency":"1851700000","band":"3","mcc":"234","mnc":"030",
        //"cellid":"1","enbid":"19321","rsrq":"0.00","rsrp":"-86.00","pci":"240",
        //"xmlprocesseddate":"2020-07-18t00:07:38z"}

        // this._asu    = undefined;
        // this._cqi    = undefined;
        // this._ecid   = undefined;
        // this._lcid   = undefined;
        // this._pci    = undefined;
        // this._rsrp   = undefined;
        // this._rsrq   = undefined;
        // this._rssnr  = undefined;
        // this._ta     = undefined;
        // this._tac    = undefined;

        // this._merge(d);
    // }


    static get(d, attrs)
    {
        return new MobileCellLteCommon(d, attrs);
    }


    static get attrs()
    {
        return _attrs;
    }


    //
    // Getters and setters
    //

    // Override
    get cellcode()
    {
        return this.pci;
    }

    
    // Override
    get signalquality()
    {
        return this.rsrq;
    }

    // Override
    get signalqualitycategory()
    {
        // Keep in sync with Android \java\com\spryfoxnetworks\quantumpath\cellular\QPMobileCellLte.java#getSigQualityCategory

        if (this.signalquality !== null) {
            if      (this.signalquality == 0)   return MobileCellCommon.signalCategories.Invalid

            else if (this.signalquality >= -12) return MobileCellCommon.signalCategories.Great;
            else if (this.signalquality >= -14) return MobileCellCommon.signalCategories.Good;
            else if (this.signalquality >= -17) return MobileCellCommon.signalCategories.Average;
            else if (this.signalquality >= -19) return MobileCellCommon.signalCategories.Poor;
        }
        
        return MobileCellCommon.signalCategories.Bad;
    }
    

    // Override
    get signalstrength()
    {
        return this.rsrp;
    }

    // Override
    get signalstrengthcategory()
    {
        // Keep in sync with Android \java\com\spryfoxnetworks\quantumpath\cellular\QPMobileCellLte.java#getSigStrengthCategory

        if (this.signalstrength !== null) {
            if      (this.signalstrength == 0)    return MobileCellCommon.signalCategories.Invalid

            else if (this.signalstrength >= -85)  return MobileCellCommon.signalCategories.Great;
            else if (this.signalstrength >= -95)  return MobileCellCommon.signalCategories.Good;
            else if (this.signalstrength >= -105) return MobileCellCommon.signalCategories.Average;
            else if (this.signalstrength >= -115) return MobileCellCommon.signalCategories.Poor;
        }
        
        return MobileCellCommon.signalCategories.Bad;
    }
    
    
    get cqi()
    {
        return this[MobileCellLteCommon.attrs._cqi];
    }


    get ecid()
    {
        return this[MobileCellLteCommon.attrs._ecid];
    }


    get lcid()
    {
        return this[MobileCellLteCommon.attrs._lcid];
    }


    get pci()
    {
        return this[MobileCellLteCommon.attrs._pci];
    }


    get rsrp()
    {
        return this[MobileCellLteCommon.attrs._rsrp];
    }


    get rsrq()
    {
        return this[MobileCellLteCommon.attrs._rsrq];
    }


    get rssnr()
    {
        return this[MobileCellLteCommon.attrs._rssnr];
    }

    
    get tac()
    {
        return this.areacode;
    }


    static get _cidMask()
    {
        return _cidMask;
    }


    //
    // Public methods
    //
    static gcidToLcid(cid)
    {
        return cid & this._cidMask;
    }


    static gcidToEcid(cid)
    {
        return cid >> 8;
    }


    static idsToGcid(enbId, lcid)
    {
        console.log(enbId + ", " + lcid  + " " + ((enbId << 8) + lcid))
        return (enbId << 8) + +lcid;
    }


    //
    // Protected methods
    //
    _merge(d, attrs)
    {
        super._merge(d, attrs, attrs ? undefined : MobileCellLteCommon.attrs);

        // If both cellId and ecid are populated, then this is a Nextivity repeater
        // cell and cellId is really lcid.  Swap and calcuate cellId
        if (this.cellId) {
            if (this._ecid && ! this._lcid) {
                this._cellId = MobileCellLteCommon.idsToGcid(this._ecid, this._lcid = this.cellId);
            }
            else {
                this._ecid  = MobileCellLteCommon.gcidToLcid(this.cellId);
                this._lcid  = MobileCellLteCommon.gcidToEcid(this.cellId);
            }
        }

        return this;
    }
}