 'use strict';


const _versionFile = '../version.json';

// import appDetails1 from '../version.json';//_versionFile;

// Needed from Node v16.14.x, not supported by Angular/WebPack
// so will need to find some mechanism to differentiate
// import appDetails1 from '../version.json' assert { type: 'json' };//_versionFile;


//import * as appDetails1 from '../version.json'; // deprecated webPack >= 5

// const appDetails = appDetails1 ? appDetails1 : appDetails2


// const _obj = {
//     company:
//         appDetails && appDetails.default
//             ? appDetails.default.company
//             : (appDetails ? appDetails.company : ""),//"Spry Fox Networks Limited",

//     companyUrl:
//         appDetails && appDetails.default
//             ? appDetails.default.companyUrl
//             : (appDetails ? appDetails.companyUrl : ""),//"http://www.spryfoxnetworks.com/",

//     date:
//         appDetails && appDetails.default
//             ? appDetails.default.date
//             : (appDetails ? appDetails.date : ""),//"2019-02-04",

//     name: 
//         appDetails && appDetails.default
//             ? appDetails.default.name
//             : (appDetails ? appDetails.name : ""),//"QuantumPath",

//     timestamp:
//         appDetails && appDetails.default
//             ? appDetails.default.timestamp
//             : (appDetails ? appDetails.timestamp : ""),//"5.0.1"

//     version:
//         appDetails && appDetails.default
//             ? appDetails.default.version
//             : (appDetails ? appDetails.version : "")//"5.0.1"
// }; // obj

let _obj = {
    company:    "",
    companyUrl: "",
    date:       "",
    name:       "",
    timestamp:  "",
    version:    ""
}; // obj


export class VersionCommon
{
    constructor()
    {
        this._obj = _obj;


        //     company:
        //         appDetails && appDetails.default
        //         ? appDetails.default.company
        //         : (appDetails ? appDetails.company : ""),//"Spry Fox Networks Limited",

        //     companyUrl:
        //         appDetails && appDetails.default
        //         ? appDetails.default.companyUrl
        //         : (appDetails ? appDetails.companyUrl : ""),//"http://www.spryfoxnetworks.com/",

        //     date:
        //         appDetails && appDetails.default
        //         ? appDetails.default.date
        //         : (appDetails ? appDetails.date : ""),//"2019-02-04",

        //     name: 
        //         appDetails && appDetails.default
        //         ? appDetails.default.name
        //         : (appDetails ? appDetails.name : ""),//"QuantumPath",

        //     timestamp:
        //         appDetails && appDetails.default
        //         ? appDetails.default.timestamp
        //         : (appDetails ? appDetails.timestamp : ""),//"5.0.1"

        //     version:
        //         appDetails && appDetails.default
        //         ? appDetails.default.version
        //         : (appDetails ? appDetails.version : "")//"5.0.1"
        // };
    }


    static set(d)
    {
        _obj = d;
    }


    getCompany()
    {
        return VersionCommon.getCompany();
    }

    static getCompany()
    {
        return _obj ? _obj.company : undefined;
    }


    getCompanyUrl()
    {
        return VersionCommon.getCompanyUrl();
    }

    static getCompanyUrl()
    {
        return _obj ? _obj.companyUrl : undefined;
    }


    getDate()
    {
        return VersionCommon.getDate();
    }

    static getDate()
    {
        return _obj ? _obj.date : undefined;
    }


    getFile()
    {
        return VersionCommon.getFile();
    }

    static getFile()
    {
        return _versionFile;
    }


    getName()
    {
        return VersionCommon.getName();
    }

    static getName()
    {
        return _obj ? (_obj.name ? _obj.name.charAt(0).toUpperCase() + _obj.name.slice(1) : _obj.name) : undefined;
    }


    getString()
    {
        return VersionCommon.getString();
    }

    static getString()
    {
        //return this._obj.name + " v" + this._obj.version + " (" + this._obj.date + ")";
        return VersionCommon.getName() + " v" + VersionCommon.getVersion() + " (" + VersionCommon.getDate() + ")";
    }


    getTimestamp()
    {
        return VersionCommon.getTimestamp();
    }

    static getTimestamp()
    {
        return _obj ? _obj.timestamp : undefined;
    }


    getVersion()
    {
        return VersionCommon.getVersion();
    }

    static getVersion()
    {
        return _obj ? _obj.version : undefined;
    }
}


// // Wrapper code to allow it to be used as both a NodeJS module and an AngularJS factory
// (function(isNode, isAngular) {

//     const service = "Version";
//     const mod     = "qpProMobileApp";

//     function angularInit()
//     {
//         console.log("Initialising " + service + " Service");

//         return VersionCommon();
//     }


//     function nodeInit()
//     {
//         return VersionCommon();
//     }


//     if (isAngular) {
//         // AngularJS service definition
//         angular
//         .module(mod)
//         .service('qp_' + service + 'Factory', VersionCommon);
//     }
//     else if (isNode) {
//         // NodeJS module definition
//         module.exports = nodeInit();
//     }

// })

// (typeof module !== 'undefined' && module.exports, typeof angular !== 'undefined'); 