'use strict'


import { Dateq }         from '../../index.mjs';
// import { DeviceMsgsModule } from '../../messages/index.mjs';
import { ElementCommon } from '../Element.class.mjs';
import { ElementType }   from '../ElementType.class.mjs';


const _attrs = {
    _configurationId:        '_configurationid',
    _configurationIteration: '_configurationiteration',
    _connectionType:         '_connectiontype',
    _end:                    '_end',
    _id:                     ElementCommon.attrs._id,
    _instanceId:             '_instanceid',
    _profileId:              '_profileid',
    _profileIteration:       '_profileiteration',
    _serviceName:            '_servicename',
    _sequenceId:             '_sequenceid',
    _sequenceIteration:      '_sequenceiteration',
    _simSlot:                '_simslot',
    _start:                  '_start',
    _type:                   '_type'
};


// const _da    = DeviceMsgsModule.attributes;
// const _dv    = DeviceMsgsModule.values;
const _field = ElementType.Test;//_da.test;

const _typeField = _attrs._type;


export const TestTypes = {
    dns:     'dns',
    ftp:     'ftp',
    icmp:    'icmp',
    http:    'http',
    sftp:    'sftp',
    udp:     'udp',
    voice:   'voice',
    web:     'web',
    youtube: 'youtube',
    
    unknown: 'unknown'
};

// test_type_dns:                            'dns',
// test_type_ftp:                            'ftp',
// test_type_http:                           'http',
// test_type_icmp:                           'icmp',
// test_type_sftp:                           'sftp',
// test_type_udp:                            'udp',
// test_type_voice:                          'voice',
// test_type_web:                            'web',
// test_type_youtube:                        'youtube',
// test_type_unknown:                        _unknown_str


export class Test extends ElementCommon
{
   
    static get attrs()
    {
        return _attrs;
    }


    static get typeField()
    {
        return _typeField;
    }


    get endI()
    {
        return this[Test.attrs._end];
    }

    get end()
    {
        if (this.isFinished()) return this.endI.displayStr;
    }


    get startI()
    {
        return this[Test.attrs._start];
    }
    
    get start()
    {
        if (this.isStarted()) return this.startI.displayStr;
    }


    get type()
    {
        return this[Test.attrs._type];
    }
    

    isFinished()
    {
        return (this.endI instanceof Dateq);
    }


    isStarted()
    {
        return (this.startI instanceof Dateq);
    }


    _merge(d, attrsE, attrsI)
    {
        super._merge(d, attrsE, {..._attrs, ...attrsI});
        // this._setAttrs2(d, attrsE ? attrsE : {..._attrs, ...attrsI}); // [TBD]
        // super._merge(d, attrsE ? attrsE : {..._attrs, ...attrsI});

        if (this[Test.attrs._start]) this[Test.attrs._start] = Dateq.get(this[Test.attrs._start]);
        if (this[Test.attrs._end])   this[Test.attrs._end]   = Dateq.get(this[Test.attrs._end]);
    }


    // _setAttrs2(d, a)
    // {
    //     if (a) {
    //         Object.entries(a).forEach(([k, v]) => {
    //             // console.log(k + ", " + v + ", " + d[v])
    //             if (k && v) this[k] = ElementCommon._getAttr(d, v);
    //         }); // forEach
    //     }
        
    //     return this;
    // }
}