'use strict'


// import { Dateq }               from '../../index.mjs';
// import { DeviceMsgsModule }    from '../../messages/index.mjs';
import { ElementCommon } from '../Element.class.mjs';
import { ElementType }   from '../ElementType.class.mjs';
// import { ElementMainCommon }   from '../ElementMain.class.mjs';


const _attrs = {
    _bssid:              '_bssid',
    _channel:            '_channel',
    _channelwidth:       '_channelwidth', 
    _connected:          '_connected',
    _frequencydl:        '_frequencydl',
    _frequencydlcenter0: '_frequencydlcenter0',
    _frequencydlcenter1: '_frequencydlcenter1',
    _ipaddr:             '_ipaddr',
    _networkid:          '_networkid',
    _mac:                '_mac',
    _rssi:               '_rssi',
    _security:           '_security',
    _ssid:               '_ssid',
    _standard:           '_standard'
}; // _attrs


// const _da    = DeviceMsgsModule.attributes;
const _field = ElementType.Wificell;//_da.wifi;


export class WifiBand {
    // Create new instances of the same class as static attributes
    static Wifi2_4GHz  = new WifiBand("2.4GHz");
    static Wifi5GHz    = new WifiBand("5GHz");
    static Wifi6GHz    = new WifiBand("6GHz");
    static Wifi60GHz   = new WifiBand("60GHz");
    static WifiUnknown = new WifiBand("Unknown");
  
    constructor(name)
    {
        this._name = name
    }


    get name()
    {
        return this._name;
    }
} // WifiBands


const _standard = {
    ac:      'ac',
    ad:      'ad',
    ax:      'ax',
    be:      'be',
    gig:     'gig',
    legacy:  'legacy',
    n:       'n',
    unknown: 'unknown',
}; // _standard


const _wifi = "wifi";
const _displayVersion = {
    _4:      _wifi + ' 4',
    _5:      _wifi + ' 5',
    _6:      _wifi + ' 6',
    _7:      _wifi + ' 7',
    gig:     'wigig',
    legacy:  'legacy',
    unknown: 'unknown',
}; // _displayTechnologies


export class WifiVersion
{
    static Ac      = new WifiVersion(_standard.ac,      _displayVersion._5);
    static Ad      = new WifiVersion(_standard.ad,      _displayVersion.gig);
    static Ax      = new WifiVersion(_standard.ax,      _displayVersion._6);
    static Be      = new WifiVersion(_standard.be,      _displayVersion._7);
    static Legacy  = new WifiVersion(_standard.legacy,  _displayVersion.legacy);
    static N       = new WifiVersion(_standard.n,       _displayVersion._4);
    static Unknown = new WifiVersion(_standard.unknown, _displayVersion.unknown);
  
    constructor(d, e)
    {
       this._standard       = d;
       this._displayVersion = e;
    }


    // Factory
    static get(d)
    {
        if (d instanceof WifiVersion) return d;
        else {
            switch (d instanceof Object ? Object.assign(new MobileCellType(), d).type : d) {
                case _standard.ac:     return WifiVersion.Ac;
                case _standard.ad:     return WifiVersion.Ad;
                case _standard.ax:     return WifiVersion.Ax;
                case _standard.be:     return WifiVersion.Be;
                case _standard.gig:    return WifiVersion.Gig;
                case _standard.legacy: return WifiVersion.Legacy;
                case _standard.n:      return WifiVersion.N;

                case _standard.unknown:
                default:
                    // Fall though
                    return WifiVersion.Unknown;
            } // switch
        }
    }


    get standard()
    {
        return this._standard;
    }


    get displayVersion()
    {
        return this._displayVersion;
    }


    // Override
    toString()
    {
        return "WifiVersion: " + this.standard + " (" + this.displayVersion + ")";
    }
} // WifiVersion


// [TBD]

export class WifiCell extends ElementCommon// ElementMainCommon
{
    static get(d, attrs)
    {
        return d
            ? (d instanceof WifiCell
                ? d
                : new WifiCell(d, attrs)
            )
            : undefined;
    }


    static get field()
    {
        return _field;
    }
    

    static get attrs()
    {
        return _attrs;
    }


    get band()
    {
        return WifiCell._getBand(this.frequencydl);
    }


    get bssid()
    {
        return this[WifiCell.attrs._bssid];
    }


    get channel()
    {
        return this[WifiCell.attrs._channel];
    }


    get channelwidth()
    {
        return this[WifiCell.attrs._channelwidth];
    }


    get connected()
    {
        return this[WifiCell.attrs._connected];
    }


    get frequencydl()
    {
        return this[WifiCell.attrs._frequencydl];
    }


    get frequencydlcenter0()
    {
        return this[WifiCell.attrs._frequencydlcenter0];
    }


    get frequencydlcenter1()
    {
        return this[WifiCell.attrs._frequencydlcenter];
    }


    get ipaddr()
    {
        return this[WifiCell.attrs._ipaddr];
    }


    get networkid()
    {
        return this[WifiCell.attrs._networkid];
    }


    get mac()
    {
        return this[WifiCell.attrs._mac];
    }


    get rssi()
    {
        return this[WifiCell.attrs._rssi];
    }


    get security()
    {
        return this[WifiCell.attrs._security];
    }


    get ssid()
    {
        return this[WifiCell.attrs._ssid];
    }


    get standard()
    {
        return this[WifiCell.attrs._standard];
    }


    get version()
    {
        return WifiCell._getVersion(this.standard);
    }


    //
    // Private methods
    //
    static _getBand(f)
    {
        if (typeof f === "number" && (! Number.isNaN(f)) ) {
            if      (f > 2401  && f < 2495)  return WifiBand.Wifi2_4GHz;
            else if (f > 5030  && f < 5990)  return WifiBand.Wifi5GHz;
            else if (f > 5945  && f < 7125)  return WifiBand.Wifi6GHz;
            else if (f > 57240 && f < 70200) return WifiBand.Wifi60GHz;
        }

        return WifiBand.WifiUnknown;
    }


    static _getVersion(f)
    {
        // Remove '11' from standard string if present
        const v = WifiVersion.get((f && f.startsWith instanceof Function && f.startsWith('11')) ? f.slice('11'.length) : f);
        return (v instanceof WifiVersion) ? v : WifiVersion.WifiUnknown
    }


    _merge(d, attrsE, attrsI)
    {
        super._merge(d, attrsE, {..._attrs, ...attrsI});
    }
}