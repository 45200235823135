'use strict';


// import { DeviceMsgsModule } from '../../messages/index.mjs';


// const _dv         = DeviceMsgsModule.values;

const _technology = {
    cdma:     'cdma',
    gsm:      'gsm',
    lte:      'lte',
    nr:       'nr',
    nr_nsa:   'nr_nsa',
    td_scdma: 'td_scdma',
    umts:     'umts',
    unknown:  'unknown',
}; // _technology
export const MobileCellTechnology = _technology;

// mobilecell_type_cdma:                     'cdma',
// mobilecell_type_gsm:                      'gsm',
// mobilecell_type_lte:                      'lte',
// mobilecell_type_nr:                       'nr',
// mobilecell_type_nr_nsa:                   'nr_nsa',
// mobilecell_type_td_scdma:                 'td_scdma',
// mobilecell_type_umts:                     'umts',
// mobilecell_type_unknown:                  _unknown_str,


const _displayTechnology = {
    _2g:      '2g',
    _3g:      '3g',
    _4g:      '4g',
    _5g:      '5g',
    _6g:      '6g',
    _unknown: 'unknown'
}; // _displayTechnologies


export class MobileCellType
{
    static Cdma     = new MobileCellType(_technology.cdma,     _displayTechnology._2g);
    static Gsm      = new MobileCellType(_technology.gsm,      _displayTechnology._3g);
    static Lte      = new MobileCellType(_technology.lte,      _displayTechnology._4g);
    static Nr       = new MobileCellType(_technology.nr,       _displayTechnology._5g);
    static Nr_nsa   = new MobileCellType(_technology.nr_nsa,   _displayTechnology._5g);
    static Td_scdma = new MobileCellType(_technology.td_scdma, _displayTechnology._4g);
    static Umts     = new MobileCellType(_technology.umts,     _displayTechnology._3g);
    static Unknown  = new MobileCellType(_technology.unknown,  _displayTechnology._unknown);
  

    constructor(d, e)
    {
       this._technology        = d;
       this._displayTechnology = e;
    }


    // Factory
    static get(d)
    {
        if (d instanceof MobileCellType) return d;
        else {
            switch (d instanceof Object ? Object.assign(new MobileCellType(), d).type : d) {
                case _technology.cdma:     return MobileCellType.Cdma;
                case _technology.gsm:      return MobileCellType.Gsm;
                case _technology.lte:      return MobileCellType.Lte;
                case _technology.nr:       return MobileCellType.Nr;
                case _technology.nr_nsa:   return MobileCellType.Nr_nsa;
                case _technology.td_scdma: return MobileCellType.Td_scdma;
                case _technology.umts:     return MobileCellType.Umts;

                case _technology.unknown:
                default:
                    // Fall though
                    return MobileCellType.Unknown;
            } // switch
        }
    }


    get displayTechnology()
    {
        return this._displayTechnology;
    }


    get technology()
    {
        return this._technology;
    }

    // Deprecated
    get type()
    {
        return this._technology;
    }


    // Override
    toString()
    {
        return "MobileCellType: " + this.technology + " (" + this.displayTechnology + ")";
    }
}