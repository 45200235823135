'use strict'


import { Test } from './index.mjs';


const _attrs = {
    _numRx:       '_numrx',
    _numTx:       '_numtx',
    _numTxActual: '_numtxactual',
    _numErrors:   '_numerrors',
    _numLoss:     '_numloss',
    _pktSize:     '_pktsize',
    _rttAverage:  '_rttaverage',
    _rttMax:      '_rttmax',
    _rttMeanDev:  '_rttmeandev',
    _rttMin:      '_rttmin',
    _waitTime:    '_waittime'
};


export class TestIcmp extends Test
{
    constructor(d, attrs)
    {
        super(d, attrs);
    }


    // Factory method
    static get(d, attrs)
    {
        return (
            d
            ? (d instanceof TestIcmp
               ? d
               : new TestIcmp(d, attrs)
              )
            : undefined
        );
    }


    static get attrs()
    {
        return _attrs;
    }


    _merge(d, attrs)
    {
        super._merge(d, attrs, attrs ? undefined : _attrs);
    }
}