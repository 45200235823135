'use strict';


// Actual code shared between NodeJS and AngularJS
export class Utils
{
    // constructor(d)
    // {
       
    // }


    //
    // Static methods
    //

    static createEnum(d)
    {
        const enumObject = {};
        for (const v of d) {
            enumObject[v] = v;
        } // for
        
        return Object.freeze(enumObject);
    }
}