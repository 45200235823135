'use strict'


import { ElementHelperCommon } from '../../Element-helper.class.mjs';
import { ElementType }         from '../../ElementType.class.mjs';

import { ConnectionCommon }    from './Connection/index.mjs';
import { GeolocationCommon }   from './Geolocation.class.mjs';
import { PowerCommon }         from './Power.class.mjs';
import { SimCommon }           from './Sim.class.mjs';


const _fields = {
    connection:  ConnectionCommon.field,
    geolocation: GeolocationCommon.field,
    power:       PowerCommon.field,
    sim:         SimCommon.field
};


export class AttributesHelperCommon
{
    // Factory method
    static get(d, type, attrs)
    {
        let o = undefined;

        if (d) {
            if (type) {
                let fn = undefined;
                switch (type) {
                    case AttributesHelperCommon.fields.connection:
                    case ElementType.Connection:
                        fn = ConnectionCommon.get;
                        break;

                    case AttributesHelperCommon.fields.geolocation: // [TBD]
                    case ElementType.Geolocation:
                        fn = GeolocationCommon.get;
                        break;

                    case AttributesHelperCommon.fields.power:
                    case ElementType.Power:
                        fn = PowerCommon.get;
                        break;

                    case AttributesHelperCommon.fields.sim:
                    case ElementType.Sim:
                        fn = SimCommon.get;
                        break;
                } // switch

                o = (fn instanceof Function)
                    ? fn(d, attrs)
                    : undefined;
            }

            else {
                // Try and get object automatically
                if      (AttributesHelperCommon._getAttr(d, AttributesHelperCommon.fields.connection)) {
                    o = ConnectionCommon.get(
                            AttributesHelperCommon._getAttr(d, AttributesHelperCommon.fields.connection), attrs);
                }

                else if (AttributesHelperCommon._getAttr(d, AttributesHelperCommon.fields.geolocation)) {
                    o = GeolocationCommon.get(
                            AttributesHelperCommon._getAttr(d, AttributesHelperCommon.fields.geolocation), attrs);
                }

                else if (AttributesHelperCommon._getAttr(d, AttributesHelperCommon.fields.power)) {
                    o = PowerCommon.get(
                            AttributesHelperCommon._getAttr(d, AttributesHelperCommon.fields.power), attrs);
                }

                else if (AttributesHelperCommon._getAttr(d, AttributesHelperCommon.fields.sim)) {
                    o = SimCommon.get(
                            AttributesHelperCommon._getAttr(d, AttributesHelperCommon.fields.sim), attrs);
                }
            }
        }

        return (o && o.isValid instanceof Function && o.isValid()) ? o : undefined;
    }


    static convertArray(d, a, t)
    {
        return (d && a)
            ? AttributesHelperCommon._convertArray(
                AttributesHelperCommon._getAttr(d, a),
                t
            ) // _convertArray

            : undefined;
    }


    static convertObjectArray(d, a, t)
    {
        let os = [];

        if (d && a) {
            const ar = AttributesHelperCommon._getAttr(d, a);
            if (ar) os = AttributesHelperCommon._convertArray(Object.values(ar), t);
        }

        return os;
    }


    static get fields()
    {
        return _fields;
    }


    static getT(d)
    {
        let t = undefined;

        if      (d instanceof ConnectionCommon)  t = AttributesHelperCommon.fields.connection;
        else if (d instanceof GeolocationCommon) t = AttributesHelperCommon.fields.geolocation;
        else if (d instanceof PowerCommon)       t = AttributesHelperCommon.fields.power;
        else if (d instanceof SimCommon)         t = AttributesHelperCommon.fields.sim;

        return t;
    }


    //
    // Private methods
    //
    static _convertArray(a, t)
    {
        let os = undefined;

        if (Array.isArray(a)) {
            os = [];
            a.forEach((o) => {
                const o2 = ElementHelperCommon.get(o, t);
                if (o2) os.push(o2);
            }); // forEach
        }
        else {
            os = ElementHelperCommon.get(a, t);
        }

        return os;
    }


    static _getAttr(d, t)
    {
        return d && t
            ? (d[t] != null
                ? d[t]
                : (d['_' + t] != null
                    ? d['_' + t]
                    : undefined
                )
            )

           : undefined;
    }
}