'use strict'


const _types = {
    good:    'good',
    average: 'average',
    poor:    'poor',
    none:    'none',
    unknown: 'unknown'
}; // _types


export class ElementState
{
    static Good    = new ElementState(_types.good);
    static Average = new ElementState(_types.average);
    static Poor    = new ElementState(_types.poor);
    static None    = new ElementState(_types.none);
    static Unknown = new ElementState(_types.unknown);
  

    constructor(d)
    {
       this._type = d;
    }


    // Factory
    static get(d)
    {
        switch(d) {
            case _types.good:    return ElementState.Good;
            case _types.average: return ElementState.Average;
            case _types.poor:    return ElementState.Poor;
            case _types.none:    return ElementState.None;

            case _types.unknown:
            default:
                return ElementState.Unknown;
        } // switch
    }


    get state()
    {
        return this._type;
    }


    // Override
    toString()
    {
        return "ElementState: " + this.state;
    }
}