'use strict';


import {
    DeviceCommon,
    DeviceTypes
}                 from './Device.class.mjs';


const _DeviceType = DeviceTypes.beacon;


// Actual code shared between NodeJS and AngularJS
export class DeviceBeaconCommon extends DeviceCommon
{
    constructor(d, attrs)
    {
        super(d, attrs);

        this._type = _DeviceType;

        console.log("[TBD] DeviceBeacon");
    }


    //
    // Static methods
    //
    
    // Factory method
    // Override
    static get(d, attrs)
    {
        return d
            ? (d instanceof DeviceBeaconCommon
                ? d
                : new DeviceBeaconCommon(d, attrs)
              )
            : undefined;
    }
}