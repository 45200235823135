'use strict'


import { Test } from './index.mjs';


const _attrs = {
    _direction: '_direction',
    _duration:  '_duration'
};


export class TestVoice extends Test
{
    constructor(d, attrs)
    {
        super(d, attrs);
    }


    // Factory method
    static get(d, attrs)
    {
        return (
            d
            ? (d instanceof TestVoice
               ? d
               : new TestVoice(d, attrs)
              )
            : undefined
        );
    }


    static get attrs()
    {
        return _attrs;
    }


    _merge(d, attrs)
    {
        super._merge(d, attrs, attrs ? undefined : _attrs);
    }
}