'use strict'


// import { DeviceMsgsModule } from '../../messages/index.mjs';

import { ElementType } from '../ElementType.class.mjs';
import { WifiCell }    from './WifiCell.class.mjs'; // has to be like this otherwise runtime error


// const _field     = DeviceMsgsModule.attributes.wificell;
const _field     = ElementType.Wificell;//_da.wificell;
const _typeField = WifiCell.typeField;


export class WifiCellHelper
{
    // Factory method
    static get(d, attrs)
    {
        if (d instanceof WifiCell) return d;

        let fn = undefined;
        if (d) {
            switch (WifiCellHelper._getType(d)) {
                default:
                    fn = WifiCell.get;
                break;
            } // switch
        }

        return fn instanceof Function
            ? fn(d, attrs) 
            : undefined;
    }


    static get field()
    {
        return _field;
    }


    //
    // Private methods
    //
    static _getType(d)
    {
        return d && _typeField && _typeField in d ? d[_typeField] : undefined;
    }
}