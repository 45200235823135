'use strict'


import { MobileCellCommon } from './MobileCell.class.mjs';


const _attrs = {

}


export class MobileCellTdscdmaCommon extends MobileCellCommon
{
    static get(d, attrs)
    {
        return new MobileCellTdscdmaCommon(d, attrs);
    }


    static get attrs()
    {
        return _attrs;
    }


    //
    // Protected methods
    //
    _merge(d, attrs)
    {
        return super._merge(d, attrs, attrs ? undefined : _attrs);
    }
}