'use strict'


import { MobileCellCommon } from './MobileCell.class.mjs';


const _attrs = {

}


export class MobileCellCdmaCommon extends MobileCellCommon
{
    static get(d, attrs)
    {
        return new MobileCellCdmaCommon(d, attrs);
    }


    static get attrs()
    {
        return _attrs;
    }


    //
    // Protected methods
    //
    _merge(d, attrs)
    {
        super._merge(d, attrs, attrs ? undefined : _attrs);

        if (d) {
            // this._asu   = this._getAttr(d, 'asu');
            // this._cqi   = this._getAttr(d, 'cqi');
            // this._ecid  = this._getAttr(d, 'enbid');
            // this._lcid  = this._getAttr(d, 'lcid');
            // this._pci   = this._getAttr(d, 'pci');
            // this._rsrp  = this._getAttr(d, 'rsrp');
            // this._rsrq  = this._getAttr(d, 'rsrq');
            // this._rssnr = this._getAttr(d, 'rssnr');
            // this._ta    = this._getAttr(d, 'ta');
            // this._tac   = this._getAttr(d, 'tac');
        }

        // If both cellId and ecid are populated, then this is a Nextivity repeater
        // cell and cellId is really lcid.  Swap and calcuate cellId
        if (this.cellId) {
            if (this._ecid && ! this._lcid) {
                this._cellId = MobileCellLteCommon.idsToGcid(this._ecid, this._lcid = this.cellId);
            }
            else {
                this._ecid  = MobileCellLteCommon.gcidToLcid(this.cellId);
                this._lcid  = MobileCellLteCommon.gcidToEcid(this.cellId);
            }
        }

        return this;
    }
}