'use strict';


import { Dateq }         from '../../index.mjs';
import { ElementCommon } from '../Element.class.mjs';
import { Utils }         from '../../Utils.mjs';


const _NotificationSeverity = {
    low:    'low',
    medium: 'medium',
    high:   'high',
    none:   'none',
}; // _NotificationSeverity


const _NotificationStatus = {
    clear: 'clear',
    set:   'set'
}; // _NotificationStatus


const _attrs = {
    _cleareddate:                           '_cleareddate',
    [ElementCommon.attrs._date]:            ElementCommon.attrs._date,
    [ElementCommon.attrs._description]:     ElementCommon.attrs._description,
    [ElementCommon.attrs._id]:              ElementCommon.attrs._id,
    [ElementCommon.attrs._lastupdateddate]: ElementCommon.attrs._lastupdateddate,
    [ElementCommon.attrs._name]:            ElementCommon.attrs._name,
    _info:                                  '_info',
    _severity:                              '_severity',
    _status:                                '_status',
    _systemid:                              '_systemid',
    _systemtype:                            '_systemtype',
    _sku:                                   '_sku'
}; // // attrs


const _typeField = undefined;


export class Notification extends ElementCommon
{
    // Factory method
    static get(d, attrs)
    {

// 22-10-16T19:52:16.217Z [qp] info: {"alarmid":22822,"serialnumber":"933942000350","assetname":"nu",
// "type":null,"alarmname":"cu link lost","alarmdescription":null,"severity":"low",
// "alarmdate":"2022-10-11t12:12:11z","alarmlistdate":"2022-10-16t20:51:35z","notificationid":23670567}
// 2022-10-16T19:52:16.217Z [qp] info: {"_date":"alarmdate","_description":"alarmdescription","_id":"alarmid",
// "_lastupdateddate":"alarmlistdate","_name":"alarmname","_severity":"severity",
// "_status":"status","_systemid":"serialnumber","_systemtype":"type","_sku":"sku"}

        return (
            d
            ? (d instanceof Notification
               ? d
               : new Notification(d, attrs)
            )
            : undefined
        );
    }


    //
    // Static methods
    //
    static get attrs()
    {
        return _attrs;
    }


    static get typeField()
    {
        return _typeField;
    }


    static isSet(s)
    {
        return s && s === _NotificationStatus.set;
    }


    static get NotificationSeverity()
    {
        return _NotificationSeverity;
    }


    //
    // Getters
    //
    get clearedDate()
    {
        return this[Notification.attrs._cleareddate];
    }

    set clearedDateI(d)
    {
        this[Notification.attrs._cleareddate] = Dateq.get(d, false);
    }


    get info()
    {
        return this[Notification.attrs._info];
    }


    get isSet()
    {
        return !!(! this.clearedDate);
    }


    get severity()
    {
        return this[Notification.attrs._severity];
    }



    get status()
    {
        return this[Notification.attrs._status];
    }


    get sku()
    {
        return this[Notification.attrs._sku];;
    }


    get systemId()
    {
        return this[Notification.attrs._systemid];
    }


    get systemType()
    {
        return this[Notification.attrs._systemtype];
    }


    //
    // Private methods
    //
    _getAttr(d, a)
    {
        return (d && a && a in d) ? d[a] : undefined;
    }


    _merge(d, attrsE, attrsI)
    {
        super._merge(d, attrsE, {...Notification.attrs, ...attrsI});

        // Causes setters to run to convert to Dateq
        this.clearedDateI = this[Notification.attrs._cleareddate];
        
        return this;

    //     if (d) {
    //         // Base
    //         // [TBD] split into parent class
    //         this._description         =           this._getAttr(d, 'alarmdescription');
    //         this._id                  =           this._getAttr(d, 'alarmid');
    //         this._lastUpdatedDate     = Dateq.get(this._getAttr(d, 'alarmlistdate'));


    //         // Object specific
    //         this._date                = Dateq.get(this._getAttr(d, 'alarmdate'));
    //         this._name                =           this._getAttr(d, 'alarmname');
    //         this._severity            =           this._getAttr(d, 'severity');
    //         this['_' + _attrs.status] =           this._getAttr(d, 'status');
    //         this._systemid            =           this._getAttr(d, 'serialnumber');
    //         this._systemType          =           this._getAttr(d, 'type');

    //         this._sku                 =           this._getAttr(d, 'sku');
    //     }
    }
}