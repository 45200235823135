'use strict';


// import { DeviceMsgsModule } from '../../../messages/index.mjs';
import { ElementCommon } from '../../Element.class.mjs';
import { ElementType }   from '../../ElementType.class.mjs';


const _attrs = {
    _accuracy:                   '_accuracy',
    _altitude:                   '_altitude',
    _bearing:                    '_bearing',
    [ElementCommon.attrs._date]: ElementCommon.attrs._date,
    _gpsTime:                    '_gpstime',
    _latitude:                   '_latitude',
    _longitude:                  '_longitude',
    _numGpsSats:                 '_numGpsSats',
    _provider:                   '_provider',
    _speed:                      '_speed',
    _speedAccuracy:              '_speedaccuracy',
    _time:                       '_time',
    _timeSinceFix:               '_timesincefix'
};


// const _da    = DeviceMsgsModule.attributes;
const _field = ElementType.Geolocation;//_da.geolocation;


export class GeolocationCommon extends ElementCommon
{
    static get(d, attrs)
    {
        return d
            ? (d instanceof GeolocationCommon
                ? d
                : new GeolocationCommon(d, attrs)
              )
            : undefined;
    }


    static get attrs()
    {
        return _attrs;
    }


    static get field()
    {
        return _field;
    }


    //
    // Getters and setters
    //
    get accuracy()
    {
        return this[GeolocationCommon.attrs._accuracy];
    }


    get altitude()
    {
        return this[GeolocationCommon.attrs._altitude];
    }


    get bearing()
    {
        return this[GeolocationCommon.attrs._bearing];
    }


    get gpsTime()
    {
        return this[GeolocationCommon.attrs._gpsTime];
    }


    get latitude()
    {
        return this[GeolocationCommon.attrs._latitude];
    }


    get longitude()
    {
        return this[GeolocationCommon.attrs._longitude];
    }


    get numGpsSats()
    {
        return this[GeolocationCommon.attrs._numGpsSats];
    }


    get provider()
    {
        return this[GeolocationCommon.attrs._provider];
    }


    get speed()
    {
        return this[GeolocationCommon.attrs._speed];
    }


    get speedAccuracy()
    {
        return this[GeolocationCommon.attrs._speedAccuracy];
    }


    get time()
    {
        return this[GeolocationCommon.attrs._time];
    }

    set time(d)
    {
        this[GeolocationCommon.attrs._time] = d;
    }


    get timeSinceFix()
    {
        return this[GeolocationCommon.attrs._timeSinceFix];
    }


    //
    // Public methods
    //

    // Override
    isValid()
    {
        return super.isValid()
            && ! ElementCommon._isEmptyIgnoringAttrbute(this, ElementCommon.attrs._id)
            && ! ElementCommon._isEmptyIgnoringAttrbute(this, ElementCommon.attrs._description)
            && this.latitude  !== null
            && this.longitude !== null;
    }


    //
    // Protected methods
    //
    
    // Override
    _merge(d, attrsE, attrsI)
    {
        super._merge(d, attrsE, {..._attrs, ...attrsI});
    }
        // super._merge(d, attrs, attrs ? undefined : GeolocationCommon.attrs);
}