'use strict'


// import { DeviceMsgsModule }  from '../messages/index.mjs';

import {
    DeviceCommon,
    DeviceHelper
}                              from './Devices/index.mjs';

// import { DeviceCommon }      from './Devices/Device.class.mjs';
// import { DeviceHelper }      from './Devices/Device-helper.class.mjs';

import { ElementHelperCommon } from './Element-helper.class.mjs';
import { ElementMainCommon }   from './ElementMain.class.mjs';
import { ElementState }        from './ElementState.class.mjs';
import { ElementType }         from './ElementType.class.mjs';


const _attrs = {
    _device:         '_device',
    _deviceQuantity: '_deviceQuantity',
}; // _attrs


// const _da    = DeviceMsgsModule.attributes;
// const _field = _da.elementmain;
const _field = ElementType.Elementmain;//_da.elementmain;


export class ElementMainWithDeviceListCommon extends ElementMainCommon
{
    // Factory method
    static get(d, attrs)
    {
        return d
            ? (d instanceof ElementMainWithDeviceListCommon
                ? d
                : new ElementMainWithDeviceListCommon(d, attrs)
            )
            : undefined;
    }


    constructor(d, attrs)
    {
        super(d, attrs);
    }


    static get attrs()
    {
        return _attrs;
    }


    static get field()
    {
        return _field;
    }


    //
    // Getters and setters
    //
    
    get deviceList()
    {
        return this[ElementMainWithDeviceListCommon.attrs._device];
    }
    

    get devices()
    {
        return Number.isInteger(this[ElementMainWithDeviceListCommon.attrs._deviceQuantity])
            ? this[ElementMainWithDeviceListCommon.attrs._deviceQuantity]
            : 0;
    }


    //
    // Public methods
    //

    // Override
    setState()
    {
        // Update state icon based on lowest of constituent devices

        let e = ElementState.None; // Only keep if no devices
        if (Array.isArray(this.deviceList)) {
            e = ElementState.Unknown;
            this.deviceList.forEach((d) => {
                if (d instanceof DeviceCommon) switch (d.state) {
                    case ElementState.Good:
                        if (ElementState.Average !== e && ElementState.Poor !== e) e = d.state;
                    break;

                    case ElementState.Average:
                        if (ElementState.Poor !== e) e = d.state;
                    break;

                    case ElementState.Poor:
                        e = d.state;
                    break;

                    case ElementState.None:
                        // Fall through
                    case ElementState.Unknown:
                        return d.state;
                } // switch
            }); // forEach
        }

        return (this.state = e);
    }


    //
    // Protected methods
    //

    // Override
    _merge(d, attrsE, attrsI)
    {
        super._merge(d, attrsE, {...ElementMainWithDeviceListCommon.attrs, ...attrsI});

        this[ElementMainWithDeviceListCommon.attrs._device] 
            = ElementHelperCommon.convertArray(this, ElementMainWithDeviceListCommon.attrs._device, DeviceHelper.field);
        if ( this[ElementMainCommon.attrs._state]) this.setState(); // need to re-calc after updating devices
        
        return this;
    }
}